<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow class="settings-dialog">
      <VCol>
        <VCard>
          <VCardTitle
            class="px-2"
            style="height: 100% !important; border: none !important;"
          >
            <TextField
              v-model="form.title"
              label="Title"
              hide-details
              placeholder="Enter title..."
              :error="$v.form.title.$error"
              @input="$v.form.title.$touch()"
              @blur="$v.$touch()"
            />
          </VCardTitle>
          <VCardText class="px-3">
            <VRow
              :class="{
                'align-center': hasVisibilty,
              }"
            >
              <VCol
                cols="2"
                :class="{
                  'pt-6': !hasVisibilty,
                }"
              >
                <span class="label">
                  Visible To:
                </span>
              </VCol>
              <VCol
                cols="10"
                class="px-0"
              >
                <VisibleTo
                  view-type="list"
                  :options="visibilityToOptions"
                  :value="form.visible_to"
                  @change="(visibility) => (form.visible_to = visibility)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <Editor
                  v-model="form.content"
                  auto-grow
                  filled
                  outlined
                  placeholder="Type content here..."
                  class="content-editor"
                />
              </VCol>
            </VRow>
            <VRow
              v-if="isFormReady && options.countries.length"
              class="mb-n4"
            >
              <VCol>
                <GeoSelectCity
                  :countries="options.countries"
                  :current-city="form.city"
                  :current-city-id="form.city_id"
                  :confirm-btn-style="{
                    backgroundColor: '#2196f3',
                    borderColor: '#2196f3',
                    color: 'white !important',
                  }"
                  @citySelected="
                    (id) => {
                      form.city_id = id;
                    }
                  "
                  @removeCity="form.city = form.city_id = null"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Lessons") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList
                  model-type="lessons"
                  :items="form.lessons"
                  @addItems="onAddItems"
                  @removeItem="onRemoveItem"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Content") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList
                  model-type="primary_contents"
                  :normal-list="false"
                  :items="form.primary_contents"
                  @addItems="onAddItems"
                  @removeItem="onRemoveItem"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Deliverables") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList
                  model-type="deliverables"
                  :normal-list="false"
                  :items="form.deliverables"
                  @addItems="onAddItems"
                  @removeItem="onRemoveItem"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("FAQs") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList
                  model-type="faqs"
                  :items="form.faqs"
                  @addItems="onAddItems"
                  @removeItem="onRemoveItem"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Mentors") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <div
                  v-for="(mentor, index) in form.mentors"
                  style="display: inline-grid;"
                  :key="index"
                >
                  <UserModal
                    v-if="!mentor.deleted"
                    closable
                    custom-class="mr-3 mb-3 members"
                    :user="mentor"
                    @close="removeMember(mentor, 'mentors')"
                  />
                </div>
                <VBtn
                  height="32px"
                  class="add-member mb-3"
                  style="border-radius: 16px !important;"
                  @click="onToggleMemberDialog('mentors')"
                >
                  <span style="color: #808080; font-size: 12px;">Add New</span>
                  <VIcon
                    right
                    color="#757575"
                    size="18px"
                  >
                    mdi-plus-circle
                  </VIcon>
                </VBtn>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Students") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <div
                  v-for="(student, index) in form.students"
                  style="display: inline-grid;"
                  :key="index"
                >
                  <UserModal
                    v-if="!student.deleted"
                    closable
                    custom-class="mr-3 mb-3 members"
                    :user="student"
                    @close="removeMember(student, 'students')"
                  />
                </div>
                <VBtn
                  height="32px"
                  class="add-member mb-3"
                  style="border-radius: 16px !important;"
                  @click="onToggleMemberDialog('students')"
                >
                  <span style="color: #808080; font-size: 12px;">Add New</span>
                  <VIcon
                    right
                    color="#757575"
                    size="18px"
                  >
                    mdi-plus-circle
                  </VIcon>
                </VBtn>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <Dialog
      :is-dialog-open="dialogs.members"
      :title="`Add ${featureName(memberDialog.type).titleize()}`"
      :hide-overlay="true"
      @toggle:dialog="onToggleMemberDialog"
    >
      <MembersForm
        ref="addMembers"
        mode="multiple"
        :type="memberDialog.type == 'mentors' ? 'any' : memberDialog.type"
        :existing="memberDialog.existing"
        :is-group="true"
        :custom-filter="{
          filter: {
            'team_user.role': ['mentor', 'student'],
          },
        }"
        @addMember="addMember"
        @removeMember="removeMember"
        @saveMembers="dialogs.members = !dialogs.members"
      />
    </Dialog>
    <FormDialog
      ref="attachForm"
      max-width="500px"
      button-text="Add"
      :can-delete="false"
      :is-dialog-open="dialogs.form"
      :model-type="dialogs.modelType"
      :do-save-action="saveAttachedElement"
      :title="`Attach ${getTitle}`"
      @toggle:form:dialog="dialogs.form = !dialogs.form"
    >
      <AttachAssociation
        v-model="defaultForm[dialogs.modelType]"
        :title="getTitle"
        :item="JSON.parse(JSON.stringify(form))"
        :section="dialogs.modelType == 'primary_contents' ? 'contents' : dialogs.modelType"
      />
    </FormDialog>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import HasMembers from "@/mixins/HasMembers";
import VisibleToMixin from "@/mixins/VisibleTo.vue";
import MetadataMixin from "@/mixins/Metadata";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import VisibleTo from "@/components/Elements/Forms/VisibleTo";

export default {
  name: "ProgramsSave",
  mixins: [FormMixin, HasMembers, VisibleToMixin, MetadataMixin],
  components: {
    MembersForm,
    VisibleTo,
  },
  data() {
    return {
      modelType: "Program",
      dialogs: {
        form: false,
        members: false,
        modelType: "program",
      },
      memberDialog: {
        type: "users",
        existing: [],
      },
      options: {
        countries: [],
      },
      defaultForm: {
        title: "",
        city_id: null,
        mentors: [],
        students: [],
        content: "",
        deliverables: [],
        reports: [],
        supplements: [],
        primary_contents: [],
        metadata: [],
        calendar_entries: [],
        faqs: [],
        lessons: [],
        files: [],
        visible_to: [],
        background_color: null,
        emoji: null,
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2),
      },
    },
  },
  computed: {
    getTitle() {
      let title =
        this.dialogs.modelType == "primary_contents"
          ? "Content"
          : this.dialogs.modelType;
      return this.featureName(title.capitalize());
    },
    hasVisibilty() {
      return !(
        this.form.visible_to instanceof Array &&
        this.form.visible_to.length === 0
      );
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "program/getFormConfig",
      doSaveProgram: "program/save",
      doGetProgram: "program/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onAddItems(section) {
      this.defaultForm[section] = this.form[section];
      this.dialogs.modelType = section;
      this.$refs.attachForm.onFormLoadingDone();
      this.$refs.attachForm.onFormReady(true);
      this.dialogs.form = !this.dialogs.form;
    },
    onRemoveItem(id, section) {
      this.form[section] = this.form[section].filter((item) => item.id != id);
    },
    saveAttachedElement() {
      this.form[this.dialogs.modelType] = this.defaultForm[this.dialogs.modelType];
      this.dialogs.form = !this.dialogs.form;
    },
    onToggleMemberDialog(type) {
      const realType = type || this.memberDialog.type;
      this.dialogs.members = !this.dialogs.members;
      this.memberDialog.type = realType;
      this.memberDialog.existing = this.form[realType];
    },
    addMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onAddMember(this.form[type], member);
    },
    removeMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onRemoveMember(this.form[type], member);
    },
    onSave() {
      const item = { ...this.form, forPreview: 1 };
      item.supplements = this.prepareRelationIds(item.supplements);
      item.primary_contents = this.prepareRelationIdsWithVisibility(item.primary_contents);
      item.reports = this.prepareRelationIds(item.reports);
      item.pipelines = this.prepareRelationIds(item.pipelines);
      item.deliverables = this.prepareRelationIdsWithVisibility(item.deliverables);
      item.faqs = this.prepareRelationIdsWithVisibility(item.faqs);
      item.lessons = this.prepareRelationIdsWithVisibility(item.lessons);
      item.pipelines = this.prepareRelationIds(item.pipelines);
      const data = this.prepareFormModel(
        { ...item },
        {
          dontSave: ["calendars", "city", "user", "threads"],
        }
      );
      return this.saveFormModel(this.doSaveProgram, null, data).then(
        (result) => {
          if (result) {
            this.$emit("updateModel", result);
            this.$emit("toggle:form:dialog");
          }
        }
      );
    },
    onGet(id) {
      return this.doGetProgram(id).then((result) => {
        return result;
      });
    },
  },
};
</script>
<style lang="scss">
.settings-dialog {
  .add-member {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.12) !important;
    background-color: #ffffff !important;
    border-radius: 10px !important;
    border: dashed 1.5px #bfbfbf;
  }
  .members .v-chip__content {
    color: #252526;
    font-weight: 500;
  }
  .members .v-chip__close {
    color: rgba(0, 0, 0, 0.54) !important;
  }
}
</style>
./Create.vue