<template>
  <component
    v-if="allItems || value"
    v-bind="{ ...$attrs, ...$props }"
    ref="component"
    append-inner-icon="arrow-down"
    :is="componentName"
    :loading="isSearching || loading"
    :disabled="isSearching || disabled || loading"
    :key="event"
    :allow-overflow="false"
    :items="allItems"
    :label="realLabel"
    :clearable="multiple === true || clearable"
    :cache-items="multiple && cacheItems"
    :return-object="returnObject"
    :chips="chips === true && multiple === true && value instanceof Array && value.length > 0"
    :small-chips="multiple === true && value instanceof Array && value.length > 0"
    :deletable-chips="multiple === true"
    :search-input.sync="search"
    :color="searchable ? (search && search.length ? 'info' : 'dark') : undefined"
    :hide-no-data="hideNoData"
    :filter="filterCallback"
    @input="onInput"
    @change="onChange"
    @click.clear="onClear"
    @blur="onBlur"
    @keyup.enter="doSearch"
    @update:search-input="onUpdateSearchInput"
  >
    <template #append-item>
      <slot name="append-item" />
    </template>
    <template #prepend>
      <slot name="prepend" />
    </template>
    <template
      #append-inner
      v-if="search && search.length"
    >
      <VBtn>Search</VBtn>
    </template>
    <template #prepend-item>
      <slot
        name="prepend-item"
        :icon="icon"
        :toggle="toggle"
      >
        <VListItem
          v-if="multiple"
          ripple
          @click="toggle"
        >
          <VListItemAction style="max-width: 26px">
            <VIcon :color="value && value.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </VIcon>
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle>Select All</VListItemTitle>
          </VListItemContent>
        </VListItem>
      </slot>
    </template>
    <template
      v-if="multiple === true || appendSelectionText"
      #selection="item"
    >
      <slot
        name="selection"
        :item="item.item"
      >
        <VChip
          close
          class="my-1"
          @click:close="onRemoveItem(item)"
        >
          <span class="text-truncate text-no-wrap">
            {{ item.item[itemText] || item.item }}
          </span>
        </VChip>
      </slot>
    </template>
    <!-- <template v-slot:item="item">
      <div
        class="text-no-wrap text-truncate"
        :style="labelStyle"
      >{{ item.item[itemText] || item.item }}</div>
    </template> -->
    <template
      v-if="appendSelectionText"
      #item="item"
    >
      <slot
        name="item"
        :item="item.item"
      />
    </template>
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </component>
</template>

<script>
import utils from "@/services/utils";
import { VAutocomplete, VSelect } from "vuetify/lib";

export default {
  name: "Autocomplete",
  props: {
    value: {
      type: [Array, Number, String, Object, Function],
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    searchInput: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    getter: {
      type: Function,
      default: null,
    },
    filterCallback: {
      type: Function,
      default: (item, queryText, itemText) => {
        return (
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
        );
      },
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
    hideNoDataMsg: {
      type: Boolean,
      default: false,
    },
    appendSelectionText: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    cacheItems: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VAutocomplete,
    VSelect,
  },
  data() {
    return {
      isSearching: false,
      event: "",
      internalValue: null,
      search: "",
      filterStatus: {
        submitText: "Search",
        submitVariant: "info",
      },
      cachedItems: [],
      filter: {},
      data: { data: [] },
      hideNoData: false,
    };
  },
  mounted() {
    this.prepareValue();
    this.data = this.data || { data: [] };
    this.ensureDataHasCurrentValue();
  },
  computed: {
    componentName() {
      return this.getter instanceof Function || this.filterable
        ? "VAutocomplete"
        : "VSelect";
    },
    allSelected() {
      return (
        this.value instanceof Array &&
        this.items instanceof Array &&
        this.value.length === this.items.length
      );
    },
    someSelected() {
      return (
        this.value instanceof Array &&
        this.value.length > 0 &&
        !this.allSelected
      );
    },
    icon() {
      if (this.allSelected) return "check_box";
      if (this.someSelected) return "indeterminate_check_box";
      return "check_box_outline_blank";
    },
    allItems() {
      return this.data.data
        .concat(this.items)
        .unique((r) => (r instanceof Object ? r[this.itemValue] : r));
    },
    labelStyle: function () {
      return {
        maxWidth: this.$element
          ? this.$element.scrollWidth || "300pt"
          : "300pt",
      };
    },
    realLabel() {
      if(this.hideLabel) {
        return "";
      }
      if (this.searchable && this.search && this.search.length && this.search !== this.value) {
        return "Press [enter] to search";
      }
      return this.label;
    },
  },
  watch: {
    "$element.selectedItem.getter": function (val) {
      if (val instanceof Function) {
        this.doSearch(val);
      }
    },
    getter: function (val) {
      this.onClear();
      if (val instanceof Function && !this.items.length) {
        this.data = { data: [] };
        this.doSearch();
      } else {
        this.data = { data: [...this.items] };
        this.ensureDataHasCurrentValue();
      }
    },
    value: function (val) {
      this.internalValue = val;
      if (val) {
        // this.prepareValue();
        this.ensureDataHasCurrentValue();
      }
    },
    searchInput: function (val) {
      this.search = val;
    },
    search: function (val) {
      this.$emit("searchTextInput", val);
      if (val && val.length >= 3) {
        this.filter.s = val;
      }
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit("input", []);
        } else {
          this.$emit(
            "input",
            this.items
              .slice()
              .map((item) =>
                item instanceof Object && !this.returnObject ? item[this.itemValue] : item
              )
          );
        }
      });
    },
    $element() {
      return this.$refs.component || this.$el || this.$vnode.elem || {};
    },
    parseErrors(errors) {
      errors =
        typeof errors === "string"
          ? [errors]
          : Object.values(errors).map((error) => {
              if (typeof error === "array") {
                return error[1];
              } else if (typeof error === "object") {
                return Object.values(error).length
                  ? Object.values(error).join("\n")
                  : error.message;
              } else {
                return error;
              }
            });
      return errors;
    },
    doSearch(getter = null) {
      const search = () => {
        this.errors = null;
        this.filterStatus.submitText = "Searching...";
        this.$emit("searchStart");

        const data = Object.assign(
          {
            options: ["items"],
          },
          utils.flattenObject(this.filter, "filter")
        );
        this.$log.debug("Data", data);

        //Explicitly add the search string parameter
        if (this.filter.s && !data.s) {
          data.s = this.filter.s;
        }

        const realGetter = getter instanceof Function ? getter : this.getter;
        this.$log.debug(
          "[Autocomplete]: Getting with params",
          data,
          realGetter
        );
        if (realGetter instanceof Function) {
          this.isSearching = true;
          return realGetter(data)
            .then((response) => {
              let result =
                response instanceof Array ? { data: response } : response;
              this.$log.debug("[Autocomplete]: Get Complete", result);
              this.$emit("searchComplete", result);
              this.filterStatus.submitText = "Done";
              this.filterStatus.submitVariant = "secondary";
              setTimeout(() => {
                this.filterStatus.submitText = "Search";
                this.filterStatus.submitVariant = "success";
              }, 2500);
              if (this.data.data.length) {
                result.data.data = (result.data.data || result.data).concat(
                  this.data.data
                );
                this.data = result;
              } else {
                this.data = result;
              }
              if (
                this.itemText &&
                this.data.data instanceof Array &&
                this.data.data.length > 0 &&
                this.data.data[0] instanceof Object
              ) {
                this.data.data = this.data.data.sortBy(this.itemText);
              }
              this.isSearching = false;
              this.onHideDataMsg((this.data.data || []).length > 0);
              return result;
              // this.pagination = result.pagination || this.pagination;
            })
            .catch((error) => {
              this.$log.error(error);
              // Only hide information if response if not a 404 since the data doesn't exist anyway
              // if([404].indexOf(error.code) === -1) {
              //     this.activity.isLoading = false;
              // }
              this.errors = this.parseErrors(
                error.errors ? error.errors : error.message
              );
              this.filterStatus.submitText = "Search";
              this.$emit("searchComplete", {
                data: [],
              });
              this.isSearching = false;
            })
            .finally(() => {
              this.isSearching = false;
            });
        } else {
          return Promise.resolve(this.allItems);
        }
      };
      this.debouncer(search, `auto-complete-search-${this.uid}`);
    },
    onInput(value) {
      this.onSetSearch();
      this.$emit("input", value);
      this.onHideDataMsg(true);
    },
    onChange(value) {
      this.onSetSearch();
      this.$emit("change", value);
    },
    onRemoveItem(item) {
      this.value.splice(item.index, 1);
      this.$emit("input", this.value.map(valueItem => valueItem instanceof Object && !this.returnObject ? valueItem.id : valueItem));
    },
    onClear() {
      this.filter.s = null;
      this.search = null;
      this.$element.lazySearch = null;
      this.onHideDataMsg(true);
    },
    onHideDataMsg(value) {
      if (this.hideNoDataMsg) {
        this.hideNoData = value;
      } else {
        this.hideNoData = false;
      }
    },
    onSetSearch() {
      this.$nextTick(() => {
        if (
          this.$element.selectedItem &&
          !(this.$element.selectedItem instanceof Array)
        ) {
          // const value =
          //   this.$element.selectedItem instanceof Object
          //     ? this.$element.selectedItem[this.itemText] ||
          //       this.$element.selectedItem
          //     : this.$element.selectedItem;
          // this.$element.internalSearch = `${value || ""}`;
          if (this.valueComparator instanceof Function) {
            // Need to do this here especially when using a custom value comparator
            this.$element.setSearch();
          }
        }
      });
    },
    onBlur(event) {
      this.onSetSearch();
      this.$emit("blur", event);
    },
    onUpdateSearchInput(event) {
      this.$emit("update:search-input", event);
      this.onSetSearch();
    },
    ensureDataHasCurrentValue() {
      const exists = this.data.data.findIndex((i) => i.id === this.value.id) > -1;
      console.log("Value is an option?", exists, this.value);
      if (this.value instanceof Object && this.value.id && !exists) {
        console.log("Adding value to data", this.value);
        this.data.data.push(this.value);
        console.log(this.data.data);
      }
    },
    prepareValue() {
      const enforceType = (value) => {
        let result = value;
        if (result instanceof Array || result instanceof Object) {
          for (const index in result) {
            this.$set(result, index, enforceType(result[index]));
          }
        } else if (
          typeof result === "string" &&
          Number.isInteger(parseInt(result))
        ) {
          console.log(typeof result, Number.isInteger(result));
          result = parseInt(result);
        }
        // console.log("Result", typeof result, result, Number.isInteger(result));
        return result;
      };
      // console.log("Autocomplete: prepareValue", this.value);
      if (this.value instanceof Array || this.value instanceof Object) {
        for (const index in this.value) {
          // console.log(
          //   "Autocomplete: Setting value",
          //   index,
          //   typeof this.value[index],
          //   this.value[index],
          //   enforceType(this.value[index])
          // );
          this.$set(this.value, index, enforceType(this.value[index]));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:root,
:root * {
  z-index: inherit;
}
</style>