<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading"
    :loading="activity.isFirstLoad || activity.isOptionsLoading"
  >
    <VRow>
      <VCol cols="12">
        <Autocomplete
          v-model="form[exactModelType]"
          multiple
          hide-details
          return-object
          :menu-props="{
            maxHeight: '272px',
          }"
          :hide-label="customOptions != null"
          :getter="customOptions ? null : getItems"
          :loading="activity.isLoading"
          :items="options[exactModelType]"
          :placeholder="`Attach to ${title}`"
          :searchable="searchText && searchText.length > 0"
          @input="$emit('input', form[exactModelType])"
          @searchComplete="enableInfiniteLoading = true"
          @searchTextInput="onSearchTextInput"
        >
          <template
            v-if="!customOptions && options[exactModelType].length"
            #append-item
          >
            <VProgressLinear
              v-if="activity.isLoading"
              indeterminate
              rounded
              height="6"
              color="primary"
            />
            <div v-intersect="endIntersect" />
          </template>
        </Autocomplete>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin],
  props: {
    section: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    customOptions: {
      type: Object,
      default: null,
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      searchText: null,
      isLastPage: false,
      skipFormValidation: true,
      enableInfiniteLoading: true,
      canGetModel: this.item === null,
      options: {
        programs: [],
        lessons: [],
        deliverables: [],
        primary_contents: [],
        pipelines: [],
        faqs: [],
      },
    };
  },
  computed: {
    exactModelType() {
      return this.modelType == 'contents' ? 'primary_contents' : this.modelType;
    },
  },
  created() {
    this.modelType = this.section;
  },
  mounted() {
    if(this.item) {
      this.form = this.item;
      this.appendItems(this.form);
    }
    if(this.customOptions) {
      this.options = this.customOptions;
    } else {
      this.emitLoading();
      this[`doGet${this.modelType.capitalize()}`]({ page: this.page }).then((result) => {
        this.options[this.exactModelType] = result.data;
        this.isLastPage = result.to === result.total;
        this.emitLoadingDone();
      });
    }
  },
  methods: {
    ...mapActions({
      doSaveProgram: "program/save",
      doGetProgram: "program/getOne",
      doGetContents: "content/getAll",
      doGetFaqs: "faq/getAll",
      doGetLessons: "lesson/getAll",
      doGetPipelines: "pipeline/getAll",
      doGetDeliverables: "deliverable/getAll",
    }),
    onGet(id) {
      return this.doGetProgram(id).then((result) => {
        this.appendItems(result.data);
        return result;
      });
    },
    endIntersect(entries, observer, isIntersecting) {
      if(isIntersecting && this.enableInfiniteLoading && !this.isLastPage && !this.activity.isLoading) {
        const params = {
          page: ++this.page,
        };
        if(this.searchText) {
          params.filter = { 
            s: this.searchText,
          };
          params.s = this.searchText;
        }
        this.emitLoading();
        this[`doGet${this.modelType.capitalize()}`](params).then((result) => {
          this.options[this.exactModelType] = this.options[this.exactModelType].concat(result.data);
          this.isLastPage = result.to === result.total;
          this.emitLoadingDone();
        });
      }
    },
    onSearchTextInput(searchText) {
      this.searchText = searchText;
      this.enableInfiniteLoading = !(searchText && searchText.length > 0);
    },
    appendItems(data) {
      data[this.exactModelType].map((item) => {
        const itemOptionIndex = this.options[this.exactModelType].findIndex((option) => option.id === item.id);
        if(itemOptionIndex === -1) {
          this.options[this.exactModelType].unshift(item);
        }
      });
    },
    getItems(params) {
      return this[`doGet${this.modelType.capitalize()}`](params).then((result) => {
        this.options[this.exactModelType] = result.data;
        this.enableInfiniteLoading = true;
        this.page = 1;
        return result;
      });
    },
    onSave() {
      let data = {
        id: this.form.id,
        [this.exactModelType]: this.exactModelType == "pipelines" ? this.prepareRelationIds(this.form[this.exactModelType]) : this.prepareRelationIdsWithVisibility(this.form[this.exactModelType])
      };
      return this.saveFormModel(this.doSaveProgram, null, data, null, false).then((result) => {
        this.$bus.$emit(
          "notificationMessage",
          `${this.featureName(this.modelType.capitalize())} attached successfully!`
        );
        this.$emit("attached", result);
      });
    },
  },
};
</script>

