<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading"
  >
    <VRow style="padding: 24px 50px;">
      <VCol cols="12">
        <h1 
          class="font-weight-black text-center"
          style="font-size: 32px;"
        >
          Invite Team Admin
        </h1>
      </VCol>
      <VCol cols="12">
        <h4
          class="font-weight-regular text-center"
          style="font-size: 18px; color: #808080; line-height: 24px;"
        >
          <template v-if="!$team.is_free && $team.subscription_plan">
            Use this form to add an Administrator license to your Team account. Admin Users can edit Team settings, create/manage {{ featureName("program", "singularize") }} details, and add more Team users.
          </template>
          <template v-else>
            Admins can edit Team settings, create/manage {{ featureName("program", "singularize") }} details, and add more Team users.
          </template>
        </h4>
      </VCol>
      <VCol 
        cols="12"
        class="dialog-form-fields"
      >
        <h4 
          class="font-weight-regular pb-2"
          style="font-size: 18px; color: #808080;"
        >
          Admin User Information
        </h4>
        <VRow>
          <VCol cols="6">
            <TextField
              v-model="form.first_name"
              hide-details
              placeholder="First Name"
              :error="$v.form.first_name.$error"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
          </VCol>
          <VCol cols="6">
            <TextField
              v-model="form.last_name"
              hide-details
              placeholder="Last Name"
              :error="$v.form.last_name.$error"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
          </VCol>
        </VRow>
        <TextField
          v-model="form.email"
          type="email"
          class="my-4"
          placeholder="Email Address"
          :error="$v.form.email.$error"
          :hide-details="$v.form.email.email"
          :error-messages="$v.form.email.email ? [] : ['Invalid email']"
          @blur="$v.$touch()"
          @input="$v.$touch()"
        />
        <template v-if="!$team.is_free">
            <template v-if="notOnEssentialPlan">
              <p
                v-if="!$team.trialEnded && !$team.subscription_plan"
                class="font-weight-regular black--text mb-0 pt-1"
                style="font-size: 15px;"
              >
                Note: During your Free Trial, you can add as many Admins as you like. When time comes to select your plan, you can pick and choose which to (pay to) keep on your team.
              </p>
              <p
                v-else
                class="font-weight-regular black--text mb-0 pt-1"
                style="font-size: 15px;"
              >
                Note: On the Enterprise Value Plan, you may invite unlimited new Admins.
              </p>
            </template>
            <VAlert
              v-else
              color="#0A5894"
              class="pa-5 mt-8 white--text"
            >
              <h2
                class="font-weight-bold pb-3"
                style="font-size: 18px;"
              >
                Purchase of Admin License
              </h2>
              <p
                class="mb-0 font-weight-regular"
                style="font-size: 15px;"
              >
                On the <span class="font-weight-bold">{{ planName }}</span>, each Admin license is an <span class="font-weight-bold">additional ${{ perMonthPrice }}/month</span>. By clicking below, you agree to our Terms of Use and understand that your license fee will be updated automatically when the invitation is accepted.
              </p>
            </VAlert>
        </template>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import { email, required } from "vuelidate/lib/validators";
import SubscriptionMixin from "@/mixins/Subscription";
export default {
  name: "InviteAdmin",
  mixins: [SubscriptionMixin],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        role: "organization-admin",
      },
    };
  },
  watch: {
    $v: {
      handler: function(value) {
        this.$emit("disabled", value.$invalid || value.$error);
      },
      deep: true,
    },
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions({
      doSendInvite: "team/invite",
    }),
    sendInvite() {
      this.$emit("loading", true);
      const params = {
        ...this.form,
        name: this.form.first_name + " " + this.form.last_name,
      };
      this.doSendInvite(params).then((invitation) => {
        if(invitation) {
          this.$bus.$emit("notificationMessage", "The invitation has been sent!");
          this.$emit('close');
          this.$emit("loading", false);
          this.$bus.$emit("addInvitation", invitation);
        }
      });
    },
  },
}
</script>