<template>
  <AppLayout :enable-footer="false">
    <VAppBar
      app
      fixed
      color="white"
      max-height="90px"
      style="box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 15px 0px !important;"
    >
      <img
        height="50px"
        :src="WeThriveLogo"
      >
    </VAppBar>
    <VContainer
      fluid
      id="billing-page"
      style="padding-top: 72px;"
    >
      <VRow
        justify-center
        class="overflow-x-hidden overflow-y-auto"
      >
        <VCol 
          lg="9"
          md="12"
          sm="12"
        >
          <Billing />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import Billing from "@/components/Account/Billing";
import WeThriveLogo from "@/images/logo_wethrivehub.png";

export default {
  name: "BillingIndex",
  components: {
    Billing,
    AppLayout
  },
  data() {
    return {
      WeThriveLogo,
    }
  },
};
</script>