<script>
import { mapActions } from "vuex";
import NumberToWords from "number-to-words";
export default {
  data() {
    return {
      NumberToWords,
      totalAdmins: 0,
      subscribedPlan: null,
      activity: {
        isLoading: false,
        isCountLoading: false,
      },
    };
  },
  computed: {
    planName() {
      if(!this.$team.trialEnded && !this.$team.subscription_plan) {
        return "Free Trial";
      } else if(this.$team.subscription_plan.is_enterprise) {
        return "Enterprise Value Plan";
      } else {
        return this.$team.subscription_plan.product.title + " plan";
      }
    },
    planQuantity() {
      return this.notOnEssentialPlan ? this.totalAdmins : this.$team.subscription_plan.quantity;
    },
    totalMonthlyFee() {
      if(this.subscribedPlan) {
        let amount = (this.$team.subscription_plan.amount / 100) * this.$team.subscription_plan.quantity; // Converting cents to dollars
        return this.subscribedPlan.billing_cycle == "year" ? amount / 12 : amount;
      }
      return this.subscribedPlan;
    },
    perMonthPrice() {
      if(this.subscribedPlan) {
        return this.subscribedPlan.billing_cycle == "year" ? this.$team.subscription_plan.product.price_yearly / 12 : this.$team.subscription_plan.product.price;
      }
      return this.subscribedPlan;
    },
    notOnEssentialPlan() {
      return (this.$team.subscription_plan && this.$team.subscription_plan.is_enterprise) || (!this.$team.trialEnded && !this.$team.subscription_plan);
    },
  },
  created() {
    if(!this.$team.is_free && this.$team.subscription_plan) {
      this.getSubscribedPlan();
    }
    if(this.notOnEssentialPlan) {
      this.getAdminsCount();
    }
  },
  methods: {
    ...mapActions({
      doGetAdminUsers: "users/getAll",
      doGetSubscribedPlan: "payment/subscribedPlan",
    }),
    getSubscribedPlan() {
      this.activity.isLoading = true;
      this.doGetSubscribedPlan(this.$team.subscription_plan.id).then((result) => {
        this.subscribedPlan = result;
        this.activity.isLoading = false;
      });
    },
    getAdminsCount() {
      const params = {
        filter: {
          'team_user.role': ['organization-admin'],
        },
      };
      this.activity.isCountLoading = true;
      this.doGetAdminUsers(params).then((result) => {
        this.totalAdmins = result.total + 1; // Include team owner
        this.activity.isCountLoading = false;
      });
    },
  },
}
</script>