<template>
  <div>
    <FormDialog
      new-actions
      button-text="Save Changes"
      :fullscreen="fullScreen.indexOf(modelType) !== -1"
      :hide-card-title="fullScreen.indexOf(modelType) === -1"
      :hide-card-actions="fullScreen.indexOf(modelType) === -1"
      :max-width="fullScreen.indexOf(modelType) === -1 ? '570px' : '1280px'"
      :hide-loverlay="true"
      :can-delete="$isUserAdmin"
      :current-model="currentModel"
      :is-dialog-open="dialogs.form"
      :title="`Add ${featureName(modelType.capitalize()).singularize()}`"
      :model-type="modelType"
      :do-save-action="['lesson', 'faq'].indexOf(modelType) === -1 ? null : getDoSaveAction"
      @toggle:form:dialog="onToggleFormDialog"
    >
      <component 
        hide-title-with-back
        :ref="`add${modelType.capitalize()}`"
        :can-get="false"
        :programs="[model]"
        :is="`${modelType.capitalize()}Save`"
        :mode="modelType == 'lesson' ? 'standalone' : 'dialog'"
        :class="{ 
          'dialog-content': modelType != 'lesson' && fullScreen.indexOf(modelType) !== -1
        }"
        @saved="onSaved"
        @updateModel="onToggleFormDialog('form')"
      />
    </FormDialog>
    <FormDialog
      new-actions
      button-text="Save Changes"
      max-width="570px"
      :can-delete="false"
      :current-model="currentModel"
      :title="currentModel ? currentModel.title : ''"
      :is-dialog-open="dialogs.edit"
      :model-type="modelType"
      @toggle:form:dialog="dialogs.edit = !dialogs.edit"
      @deleteItem="dialogs.deleteOptions = !dialogs.deleteOptions"
    >
      <component 
        :is="`${modelType.capitalize()}Save`"
        :id="currentModel ? currentModel.id : null" 
        :model="modelType"
        :item="currentModel"
        :use-id-from-route="false"
        @updateModel="(result) => onUpdated(result, 'edit')"
      />
    </FormDialog>
    <FormDialog
      max-width="500px"
      :can-delete="false"
      :current-model="currentModel"
      :is-dialog-open="dialogs.rename"
      :model-type="modelType"
      :title="`Rename ${featureName(modelType.capitalize())}`"
      @toggle:form:dialog="dialogs.rename = !dialogs.rename"
    >
      <RenameCard 
        :id="currentModel ? currentModel.id : null" 
        :model="modelType"
        :item="currentModel"
        :for-feature="forFeature"
        :use-id-from-route="false"
        @updated="(result) => onUpdated(result, 'rename')"
        @toggle:form:dialog="dialogs.rename = !dialogs.rename"
      />
    </FormDialog>
    <Dialog
      max-width="640px"
      :is-dialog-open="dialogs.view"
      :title="`View ${featureName(modelType.capitalize())}`"
      @toggle:dialog="onToggleViewDialog"
    >
      <component 
        mode="dialog"
        :is="`${modelType.capitalize()}View`"
        :id="currentModel ? currentModel.id : null"
      />
    </Dialog>
    <FormDialog
      max-width="500px"
      :can-delete="false"
      :is-dialog-open="dialogs.attachAssociation"
      :model-type="modelType"
      :title="`Attach ${getTitle}`"
      @toggle:form:dialog="dialogs.attachAssociation = !dialogs.attachAssociation"
    >
      <AttachAssociation
        :title="getTitle"
        :section="modelType"
        @attached="(result) => onAttached(result, 'attachAssociation')"
      />
    </FormDialog>
    <DeleteOptionsDialog 
      model-type="program"
      :item="currentModel"
      :item-type="modelType.capitalize()"
      :is-loading="isLoading"
      :is-dialog-open="dialogs.deleteOptions"
      @toggleEditDialog="dialogs.edit = !dialogs.edit"
      @toggleDeleteDialog="dialogs.deleteOptions = !dialogs.deleteOptions"
    />
    <Confirm ref="confirmDelete" />
    <Confirm ref="confirmRemove" />
  </div>
</template>
<script>
import DialogViewMixin from "@/mixins/DialogView";
import ContentSave from "@/components/Content/Save";
import LessonSave from "@/components/Lessons/Save";
import DeliverableSave from "@/components/Deliverables/Save";
import FaqSave from "@/components/FAQ/Save";
import ContentView from "@/components/Content/View";
import DeliverableView from "@/components/Deliverables/View";
import FaqView from "@/components/FAQ/View";
import RenameCard from "../Rename";
import DeleteOptionsDialog from "@/components/Elements/Dialogs/DeleteOptions";

export default {
  mixins: [DialogViewMixin],
  props: {
    model: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentSave,
    LessonSave,
    DeliverableSave,
    FaqSave,
    ContentView,
    DeliverableView,
    FaqView,
    RenameCard,
    DeleteOptionsDialog,
  },
  data() {
    return {
      modelType: null,
      forFeature: false,
      dialogs: {
        rename: false,
        edit: false,
        deleteOptions: false,
        attachAssociation: false,
      },
      fullScreen: ["lesson", "faq"],
    }
  },
  computed: {
    getTitle() {
      let title =
        this.modelType == "primary_contents"
          ? "Content"
          : this.modelType;
      return this.featureName(title.capitalize());
    },
  },
  methods: {
    toggleCreateDialog(type) {
      this.modelType = type;
      this.onToggleFormDialog();
    },
    toggleViewDialog(type, item) {
      this.modelType = type;
      this.onSetViewDialogModel(item);
    },
    toggleRenameDialog(type, item, forFeature = false) {
      this.modelType = type;
      this.currentModel = item;
      this.forFeature = forFeature;
      this.dialogs.rename = !this.dialogs.rename;
    },
    toggleAttachDialog(type, item) {
      this.modelType = type;
      this.currentModel = item;
      this.dialogs.attachAssociation = !this.dialogs.attachAssociation;
    },
    toggleEditDialog(type, item) {
      this.modelType = type;
      this.currentModel = item;
      this.dialogs.edit = !this.dialogs.edit;
    },
    onSaved(result) {
      this.$emit("addedSectionItem", this.modelType, result);
    },
    onUpdated(result, dialogType) {
      this.$emit("updatedSectionItem", this.modelType.toLowerCase(), result);
      this.dialogs[dialogType] = !this.dialogs[dialogType];
    },
    onAttached(result, dialogType) {
      this.$emit("updatedAssociation", this.modelType.toLowerCase().singularize(), result);
      this.dialogs[dialogType] = !this.dialogs[dialogType];
    },
    getDoSaveAction() {
      return this.$refs[`add${this.modelType.capitalize()}`].onSave();
    },
  }
}
</script>
<style lang="scss">
.dialog-content .row {
  margin-top: -12px !important;
}
</style>