<template>
  <VRow class="auth-form">
    <VCol 
      cols="12" 
      class="text-center"
    >
      <img 
        height="70px"
        :src="WeThriveLogo"
      >
    </VCol>
    <VCol 
      cols="12"
      class="d-flex justify-center"
    >
      <VCard 
        width="590px"
        style="border-radius: 14px; padding: 30px 44px; box-shadow: 0px 4px 25px 0px #0000000D;"
      >
        <VCardText>
          <h1
            class="font-weight-black text-center"
            style="font-size: 36px;"
          >
            Ok, we’ll be in touch.
          </h1>
          <p
            class="text-h6 font-weight-regular py-5"
            style="color: #808080; line-height: 27px;"
          >
            We’re so glad to hear that you’re interested in our Enterprise Value plan! Our team will follow up shortly to provide more information to get started. In the meantime, you can continue using WeThrive by selecting our Program Essentials Plan. 
          </p>
          <div class="text-center">
            <span
              class="font-weight-bold link"
              style="color: #333333 !important;"
              @click="$router.push({
                name: 'billing',
              })"
            >
              Back to plan selection
            </span>
          </div>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>
<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
export default {
  name: "EnterpriseConfirmation",
  data() {
    return {
      WeThriveLogo,
    };
  },
}
</script>