<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading"
  >
    <VRow style="padding: 14px 40px 4px;">
      <VCol cols="12">
        <h1 
          class="font-weight-black"
          style="font-size: 36px; line-height: 42px;"
        >
          How Many Cooks?
        </h1>
      </VCol>
      <VCol cols="12">
        <span 
          class="text-h6 font-weight-regular"
          style="color: #808080; line-height: 27px;"
        >
          Workspace Admins have access to your team content, can create new program {{ featureName("Programs") }}, and can view, edit, and manage all {{ featureName("Programs") }} in your workspace.
          <br><br>
          Each Admin license on the {{ planTitle }} plan is ${{ price }}/month, billed {{ isMonthly ? "monthly" : "annually" }}. To change your number of admins, select which users to remove below.
        </span>
      </VCol>
      <VCol cols="12">
        <VList id="admins">
          <VListItem class="px-0">
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 22px;"
              >
                {{ $user.name }} (You)
              </VListItemTitle>
              <VListItemSubtitle 
                class="text-h6"
                style="color: #666666;"
              >
                {{ $user.email }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <VList
                width="180px"
                class="d-flex"
              >
                <VListItemTitle
                  class="font-weight-medium font-italic text-wrap text-right px-2"
                  style="font-size: 17px; color: #666666;"
                >
                  Cannot remove Team Owner
                </VListItemTitle>
                <VListItemAction class="ml-0">
                  <img :src="require('../../../images/lock-icon.png')">
                </VListItemAction>
              </VList>
            </VListItemAction>
          </VListItem>
          <VListItem
            v-for="(user, index) in data.data"
            class="px-0"
            :key="index"
          >
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 22px;"
              >
                {{ user.name }}
              </VListItemTitle>
              <VListItemSubtitle 
                class="text-h6"
                style="color: #666666;"
              >
                {{ user.email }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <VChip
                class="rounded-pill px-4"
                :color="user.isRemoved ? '#FF2A7A' : '#ffffff'"
                :outlined="!user.isRemoved"
                :style="{
                  height: '48px',
                  fontSize: '18px',
                  fontWeight: '600', 
                  color: user.isRemoved ? '#ffffff' : '#adadad',
                  border: user.isRemoved ? 'none' : '1px solid #5A5A5A',
                }"
                @click="onRemoveUser(index, user)"
              >
                Remove
              </VChip>
            </VListItemAction>
          </VListItem>
          <infinite-loading
            v-if="canGetMore"
            :identifier="new Date()"
            @infinite="getAdminUsers"
          />
        </VList>
        <VAlert
          v-if="removedAdminsCount"
          color="#FF2A7A"
          class="rounded-lg white--text my-4"
          style="padding: 10px 20px;"
        >
          <template #prepend>
            <img :src="require('../../../images/warning-icon.png')">
          </template>
          <span 
            class="d-block ml-4"
            style="font-size: 22px; line-height: 26px;"
          >
            You’re about to remove {{ removedAdminsCount }} admin user. They will no longer be able to manage {{ featureName("Programs") }} or change Team settings.
          </span>
        </VAlert>
        <VList class="mt-4">
          <VListItem class="px-0">
            <VListItemContent>
              <VListItemTitle style="font-size: 22px; display: contents;">
                Subtotal
                <VSpacer />
                <span style="font-size: 26px;">${{ subTotal }}</span>
                /month
              </VListItemTitle>
              <VListItemSubtitle 
                class="font-italic"
                style="font-size: 19px; color: inherit;"
              >
                <VRow>
                  <VCol 
                    cols="4"
                    class="pa-0"
                  >
                    {{ totalAdmin }} Admin Licenses
                  </VCol>
                  <VCol 
                    cols="4"
                    class="text-h6 font-weight-regular pa-0 mt-n2"
                  >
                    ............................................
                  </VCol>
                  <VCol 
                    cols="4"
                    class="text-right pa-0 pr-1"
                  >
                    billed {{ isMonthly ? "monthly" : "annually" }}
                  </VCol>
                </VRow>
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCol>
      <VCol 
        cols="12"
        class="text-center"
      >
        <VBtn
          block
          color="primary"
          height="54px"
          class="black--text"
          style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
          :disabled="activity.isSaveDisabled"
          @click="onSave"
        >
          <span 
            class="subtitle-1"
            style="font-weight: 600; color: #333333;"
          >
            Save Changes
          </span>
        </VBtn>
      </VCol>
      <VCol 
        cols="12"
        class="text-center"
      >
        <span 
          class="link body-1"
          style="color: #000000 !important;"
          @click="$emit('toggle:dialog')"
        >
          Cancel
        </span>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Admins",
  props: {
    price: {
      type: Number,
      default: 0,
    },
    planTitle: {
      type: String,
      default: null,
    },
    isMonthly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activity: {
        isLoading: true,
        isSaveDisabled: true,
      },
      data: {
        data: [],
        current_page: 0,
        last_page: 0,
        total: 0,
      },
      admins: JSON.parse(localStorage.getItem("remove-admin")) || [],
    };
  },
  computed: {
    canGetMore() {
      return this.data.current_page < this.data.last_page;
    },
    removedAdmins() {
      return this.data.data.filter((item) => item.isRemoved === true).map((item) => item.id);
    },
    removedAdminsCount() {
      return this.removedAdmins.length;
    },
    totalAdmin() {
      return this.data.total - this.removedAdminsCount;
    },
    subTotal() {
      return this.totalAdmin * this.price;
    },
  },
  created() {
    this.getAdminUsers();
  },
  methods: {
    ...mapActions({
      doGetAdminUsers: "users/getAll",
    }),
    getAdminUsers($infinite) {
      const params = {
        page: 
            this.data.data && this.data.current_page < this.data.last_page
              ? this.data.current_page + 1
              : 1,
        filter: {
          'team_user.role': ['organization-admin'],
        },
      };
      this.doGetAdminUsers(params).then((result) => {
        this.data.current_page = result.current_page;
        this.data.last_page = result.last_page;
        this.data.total = result.total + 1; // Include team owner
        this.data.data.push(...result.data.map((item) => {
          item.isRemoved = this.admins.indexOf(item.id) > -1;
          return item;
        }).filter((item) => item.id != this.$user.id));
        if($infinite) {
          $infinite.loaded();
          if(result.current_page >= result.last_page) {
            $infinite.complete();
          }
        }
        this.activity.isLoading = false;
      });
    },
    onRemoveUser(index, user) {
      this.$set(this.data.data[index], "isRemoved", !user.isRemoved);
      this.data = {
        ...this.data,
      };
      if(this.data.data[index].isRemoved) {
        this.admins.push(user.id);
      } else {
        delete this.admins[this.admins.indexOf(this.data.data[index].id)];
      }
      this.activity.isSaveDisabled = false;
    },
    onSave() {
      this.$emit('save', this.removedAdmins, this.data.total - this.removedAdmins.length);
      localStorage.setItem("remove-admin", JSON.stringify(this.admins));
    },
  },
}
</script>