<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h4>{{ featureName('Pipelines') }}</h4>
        </template>
        <template #create-action>
          <ExpandableButton
            v-if="$isUserAdmin" 
            large
            class="wethrive"
            icon="add"
            :to="{
              name: `${modelType.singularize()}.create`,
              params: {
                team: $team.slug,
              },
            }"
            :tooltip-text="`Add ${featureName(modelType.capitalize())}`"
          />
        </template>
        <template #actions-left>
          <ExpandableButton
            icon
            large
            depressed
            color="success"
            text="Share"
            button-class="mr-1"
            @click="scrollToShareLinks"
          />
          <ExpandableButton
            v-if="!$isUserStudent"
            icon
            text="Export"
            button-class="ml-1"
            color="info"
            beta-feature="pipeline_submitted_export"
            :depressed="true"
            :large="true"
            :flex="true"
            :block="$vuetify.breakpoint.smAndDown"
            :beta-options="{
              asIcon: false,
              overlap: true,
              offsetY: '15',
              offsetX: '15',
              class: isBetaFeature('pipeline_submitted_export') ? 'mr-2' : ''
            }"
            :to="{
              hash: '#pipelines-export'
            }"
            :tooltip-text="`Export Submitted ${featureName('Pipelines').toLowerCase()}`"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol style="flex: 1 1 auto">
      <VTabs
        grow
        lazy
        slider-color="primary"
        class="d-none"
      >
        <VTab
          v-for="(tab, key) in tabs"
          :to="{
            hash: tab.url
          }"
          :key="key"
          lazy
        >
          <BetaBadge
            enable-tooltip
            :is-beta="isBetaFeature(tab.feature)"
          >
            {{ tab.title }}
          </BetaBadge>
        </VTab>
      </VTabs>
      <VTabsItems
        touchless
        :value="activeUrlTab"
      >
        <VTabItem>
          <Data
            ref="data"
            :existing-config="config"
          />
          <ShareLinks />
        </VTabItem>
        <VTabItem>
          <Submitted
            ref="submitted"
            mode="embedded"
          />
        </VTabItem>
        <VTabItem v-if="false && $isUserAdmin || $isUserMentor">
          <Import ref="imported" />
        </VTabItem>
        <VTabItem v-if="$isUserAdmin || $isUserMentor">
          <Export ref="exported" />
        </VTabItem>
      </VTabsItems>
      <FormDialog
        v-if="currentModel"
        fullscreen
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel ? currentModel.title : ''"
        :model-type="modelType"
        @toggle:form:dialog="onToggleFormDialog"
        @deleteItem="onDeleteItem"
      >
        <Save
          ref="saveForm"
          mode="dialog"
          v-if="$isUserAdmin"
          :id="currentModel ? currentModel.id : null"
        />
        <Submit
          v-else
          ref="saveForm"
          mode="dialog"
          :pipeline="currentModel"
        />
      </FormDialog>
    </VCol>
  </VRow>
</template>

<script>
import HasStatsMixin from "@/mixins/HasStats";
import Data from "./Data";
import Submitted from "./Submitted";
import Import from "./Import";
import Export from "./Export";
import { mapActions } from "vuex";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import ShareLinks from "@/components/Organization/Share";
import HasTabsMixin from "@/mixins/HasTabs";
import DialogViewMixin from "@/mixins/DialogView";
import DeletesItemsMixin from "@/mixins/DeletesItems";
import Save from "./Save";
import Submit from "./Submit";

export default {
  name: "PipelinesIndex",
  components: {
    Data,
    Submitted,
    IndexHeader,
    Import,
    Export,
    Save,
    Submit,
    ShareLinks,
  },
  mixins: [HasStatsMixin, DialogViewMixin, DeletesItemsMixin, HasTabsMixin],
  data() {
    return {
      date: null,
      modelType: "pipeline",
      statOptions: {
        inflect: {
          submitted: {
            plural: "Submitted",
          },
        },
      },
      config: {
        stats: {
          "-": "-",
        },
      },
    };
  },
  computed: {
    tabs() {
      return [
        {
          title: this.featureName("Pipelines"),
          url: "#pipelines",
          enabled: true,
        },
        {
          title: "Submitted",
          url: "#pipelines-submitted",
          enabled: true,
        },
        {
          title: "Import Submitted",
          url: "#pipelines-import",
          enabled: this.$isUserAdmin || this.$isUserMentor,
          enabled: false,
        },
        {
          title: "Export Submitted",
          url: "#pipelines-export",
          enabled: this.$isUserAdmin || this.$isUserMentor,
          feature: "pipeline_submitted_export",
        },
      ].filter((t) => t.enabled);
    },
  },
  created() {
    // Restrict Pipeline page access for Moderators who haven't been assigned as reviewer in any pipeline.
    if(this.$isUserMentor && !this.$user.pipelines_count) {
      this.$router.push({ name: "program.index" });
    }
  },
  methods: {
    ...mapActions({
      doDeletePipeline: "pipeline/delete",
    }),
    onDelete(pipeline) {
      return this.doDeletePipeline(pipeline.id);
    },
    scrollToShareLinks() {
      const container = document.querySelector(".main-content-container .container");
      container.scroll({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>
