<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading"
    :class="{
      'mt-8': activity.isLoading,
    }"
  >
    <VRow column>
      <VCol style="flex: 0 0 64px">
        <IndexHeader
          mode="standalone"
          :owner="this"
        >
          <template #create-action>
            <ExpandableButton
              large
              icon
              text="+ Invite Admins"
              button-class="wethrive sendInviationBtn mr-1"
              button-style="box-shadow: none !important;"
              @click="dialogs.invitation.status = true"
            />
          </template>
        </IndexHeader>
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VCard class="rounded-0 px-4 pb-4">
          <VCardText>
            <VRow>
              <VCol 
                lg="9"
                md="12"
              >
                <h2
                  class="text-h6 pb-1"
                  style="font-weight: 600;"
                >
                  Your Team Plan
                </h2>
                <p
                  v-if="($team.subscription_plan && $team.subscription_plan.is_enterprise) || !$team.trialEnded"
                  class="black--text"
                >
                  You are on the <b>{{ planName }}</b> with <b>{{ NumberToWords.toWords(planQuantity) }} ({{ planQuantity }}) admin licenses</b>. You may invite unlimited new Admins below. Team Users are free, but have read-only access to {{ featureName("Programs") }} and cannot modify team settings.
                </p>
                <p 
                  v-else 
                  class="black--text"
                >
                  You are on the <b>{{ planName }}</b> with <b>{{ NumberToWords.toWords(planQuantity) }} ({{ planQuantity }}) admin licenses</b>. Your total license fee is <b>${{ totalMonthlyFee }}/month</b>. You may invite new Admins below at an additional <b>${{ perMonthPrice }}/month each</b>. Team Users are free, but have read-only access to {{ featureName("Programs") }} and cannot modify team settings.
                </p>
                <VSkeletonLoader
                  grid-list-lg
                  fluid
                  type="sentences,sentences"
                  width="250px"
                  :loading="isPortalLoading"
                >
                  <div
                    v-for="(link, index) of links"
                    class="mb-1"
                    style="color: #0A5894; font-weight: 600;"
                    :key="index"
                  >
                    <span 
                      class="text-decoration-underline"
                      style="cursor: pointer;"
                      @click="link.action"
                    >
                      {{ link.title }}
                    </span> >
                  </div>
                </VSkeletonLoader>
              </VCol>
            </VRow>
            <VRow v-if="customerPortalDetails">
              <VCol 
                lg="11"
                md="12"
              >
                <VCard
                  color="#0A5894"
                  class="pa-2"
                >
                  <VCardText class="white--text">
                    <h2
                      class="font-weight-bold pb-1"
                      style="font-size: 27px;"
                    >
                      Upgrade to Enterprise
                    </h2>
                    <VRow>
                      <VCol 
                        cols="12"
                        class="d-flex"
                      >
                        <p 
                          class="mb-0"
                          style="font-size: 17px;"
                        >
                          Our <b>Enterprise Value</b> plan is designed for organizations who fund and/or operate dozens of impact programs. It includes white-glove onboarding, content migration, custom reporting, and more.
                        </p>
                        <VSpacer />
                        <VBtn
                          :loading="isCustomQuoteLoading"
                          @click="requestCustomQuote"
                        >
                          <span class="subtitle-1 font-weight-bold black--text">
                            Contact us for a Custom Quote
                          </span>
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VCardText>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
        <Dialog
          enable-footer-slots
          max-width="542px"
          custom-class="invite-dialog"
          :dismissable="false"
          :is-dialog-open="dialogs.invitation.status"
          :action-style="{
            'border-top': '1px solid #FAF9FF',
            'box-shadow': '0px 0px 25px 0px #0000000D',
          }"
        >
          <template #footer-left>
            <VBtn
              text
              height="54px"
              @click="dialogs.invitation.status = false"
            >
              <span 
                class="body-1"
                style="color: #000000 !important;"
              >
                Cancel
              </span>
            </VBtn>
          </template>
          <template #footer-right>
            <VBtn
              color="primary"
              height="54px"
              class="black--text"
              style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
              :disabled="dialogs.invitation.disabled"
              :loading="dialogs.invitation.loading"
              @click="$refs.inviteAdmin.sendInvite()"
            >
              <span 
                class="text-body-2"
                style="font-weight: 600;"
              >
                {{ $team.trialEnded && $team.subscription_plan ? "Confirm Purchase" : "Confirm & Add" }}
              </span>
            </VBtn>
          </template>
          <InviteAdmin 
            ref="inviteAdmin"
            @close="dialogs.invitation.status = false" 
            @disabled="(status) => dialogs.invitation.disabled = status"
            @loading="(status) => dialogs.invitation.loading = status"
          />
        </Dialog>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import SubscriptionMixin from "@/mixins/Subscription";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import InviteAdmin from "../Users/InviteAdmin";
export default {
  name: "PlanIndex",
  mixins: [SubscriptionMixin],
  components: {
    IndexHeader,
    InviteAdmin,
  },
  data() {
    return {
      modelType: "Plan & Billing Info",
      isCustomQuoteLoading: false,
      customerPortalDetails: null,
      dialogs: {
        invitation: {
          disabled: true,
          loading: false,
          status: false,
        },
      },
    };
  },
  computed: {
    links() { 
      return [
        {
          title: "Cancel plan",
          action: () => window.location.href = this.customerPortalDetails.url,
          enabled: this.customerPortalDetails != null,
        },
        {
          title: "Modify billing info",
          action: () => window.location.href = this.customerPortalDetails.url,
          enabled: this.customerPortalDetails != null,
        },
        {
          title: "Contact WeThrive support",
          action: () => this.$bus.$emit("toggleIntercom"),
          enabled: true,
        },
        {
          title: "Add or remove Admin Licenses",
          action: () => this.$router.push({
            name: "user.index",
            params: {
              team: this.$team.slug,
            },
          }),
          enabled: true,
        },
      ].filter((item) => item.enabled === true);
    },
  },
  created() {
    if(this.$team.is_free) {
      this.$router.push({
        name: "program.index",
        params: {
          team: this.$team.slug,
        },
      });
    } else if(this.$team.trialEnded && !this.$team.subscription_plan.is_enterprise) {
      this.getCustomerPortalDetails();
    }
  },
  methods: {
    ...mapActions({
      doRequestCustomQuote: "payment/customQuote",
      doGetCustomerPortalDetails: "payment/customerPortal",
    }),
    requestCustomQuote() {
      this.isCustomQuoteLoading = true;
      this.doRequestCustomQuote().then((result) => {
        if(result) {
          this.isCustomQuoteLoading = false;
          this.$bus.$emit("notificationMessage", "We'll be in touch soon!", true);
        }
      });
    },
    getCustomerPortalDetails() {
      this.isPortalLoading = true;
      this.doGetCustomerPortalDetails().then((result) => {
        if(result) {
          this.customerPortalDetails = result;
          this.isPortalLoading = false;
        }
      });
    },
  },
}
</script>
<style lang="scss">
.sendInviationBtn {
  border-radius: 10px !important;
  padding: 10px 30px !important;

  .v-btn__content {
    font-size: 18px !important;
    font-weight: 600;
    color: #000000 !important;
  }
}
.invite-dialog {
  .v-card__actions {
    .col {
      padding: 0px 44px;
    }
  }
}
</style>