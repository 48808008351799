<template>
  <VDialog
    v-model="dialog"
    :max-width="maxWidth || options.width"
    :persistent="persistent"
    @keydown.esc="cancel"
  >
    <VCard>
      <VCardTitle
        v-if="altView"
        class="justify-center mb-n3 pt-5"
        style="font-weight: 900; font-size: 18px; border: none;"
      >
        {{ altTitle }}
      </VCardTitle>
      <VToolbar
        v-else
        dark
        dense
        text
        :color="options.color"
      >
        <VToolbarTitle class="white--text">
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <VCardText 
        v-show="!!message" 
        :class="{
          'px-4 text-center': altView,
        }"
        :style="{
          color: altView ? '#808080' : 'inherit',
        }"
      >
        {{ message }}
        <slot />
      </VCardText>
      <VCardActions
        v-if="altView"
        class="pb-4"
      >
        <VCol class="pa-0 pr-1">
          <VBtn
            block
            depressed
            color="#ececf1"
            style="color: #808080;"
            @click.native="cancel"
          >
            {{ altCancelText }}
          </VBtn>
        </VCol>
        <VCol class="pa-0 pl-1">
          <VBtn
            block
            color="#ff2a7a"
            class="white--text"
            style="box-shadow: 0 1.5px 3px 0 rgba(255, 42, 122, 0.12);"
            @click.native="agree"
          >
            {{ altConfirmText }}
          </VBtn>
        </VCol>
      </VCardActions>
      <VCardActions
        v-else
        class="pt-0"
      >
        <VCol class="pa-0 pr-1">
          <VBtn
            block
            :color="confirmColor"
            @click.native="agree"
          >
            {{ confirmText }}
          </VBtn>
        </VCol>
        <VCol
          v-if="!hideCancel"
          class="pa-0 pl-1"
        >
          <VBtn
            block
            depressed
            :color="cancelColor"
            @click.native="cancel"
          >
            {{ cancelText }}
          </VBtn>
        </VCol>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * created() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  props: {
    cancelText: {
      type: String,
      default: "No"
    },
    confirmText: {
      type: String,
      default: "Yes"
    },
    cancelColor: {
      type: String,
      default: "info"
    },
    confirmColor: {
      type: String,
      default: "error"
    },
    altView: {
      type: Boolean,
      default: false,
    },
    altTitle: {
      type: String,
      default: "Delete?",
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    altCancelText: {
      type: String,
      default: "Cancel",
    },
    altConfirmText: {
      type: String,
      default: "Delete",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 290
    }
  }),

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
