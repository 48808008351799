<template>
  <div>
    <template v-if="!showInvitationsOnly">
      <SendInvitation @addInvitation="addInvitation" />
      <br>
    </template>
    <MailedInvitations
      :show-title="!showInvitationsOnly"
      :invitations="filteredInvitations"
      :is-loading="isLoading"
      @refreshInvitations="getInvitations"
      @removeInvitation="removeInvitation"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import MailedInvitations from "./MailedInvitations";
import SendInvitation from "./SendInvitation";

export default {
  name: "Invitations",
  props: {
    showInvitationsOnly: {
      type: Boolean,
      default: false,
    },
    programId: {
      type: Number,
      default: null,
    },
  },
  components: {
    MailedInvitations,
    SendInvitation,
  },
  data() {
    return {
      isLoading: true,
      invitations: []
    };
  },
  created() {
    this.getInvitations();
  },
  computed: {
    filteredInvitations() {
      if(this.programId) {
        return this.invitations.filter((invitation) => {
          let parsedInvitation = JSON.parse(invitation.into_programs);
          if(parsedInvitation instanceof Array && parsedInvitation.findIndex((item) => item.id == this.programId) != -1) {
            return invitation;
          }
        })
      }
      return this.invitations;
    }
  },
  methods: {
    ...mapActions({
      doDeleteInvite: "team/deleteInvite",
      doGetInvitations: "team/invitations"
    }),
    getInvitations() {
      this.isLoading = true;
      this.doGetInvitations().then(invitations => {
        this.invitations = invitations;
        this.isLoading = false;
      });
    },
    addInvitation(invitation) {
      this.invitations.push(invitation)
    },
    removeInvitation(id) {
      const index = this.invitations.findIndex(
        invitation => invitation.id === id
      );
      if (index > -1) {
        this.invitations.splice(index, 1);
      }
    }
  }
};
</script>