import BaseApi from "./_base-api";

/**
 * Payment API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getPlans() {
		this.log().info("[Services: Payment]: Get Payment Plans");
		return this.api()
			.get("api/payments/subscriptions/products")
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Get Payment Plans]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	checkout(params) {
		this.log().info("[Services: Payment]: Checkout", params);
		return this.api()
			.post("api/payments/subscriptions/checkout", params)
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Checkout]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	customQuote() {
		this.log().info("[Services: Payment]: Custom Quote");
		return this.api()
			.post("api/payments/subscriptions/enterprise/plan")
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Custom Quote]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getSubscribedPlan(id) {
		this.log().info("[Services: Payment]: Get Subscribed Plan", id);
		return this.api()
			.get("api/payments/subscriptions/detail/" + id)
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Get Subscribed Plan]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	updateSubscription(params) {
		this.log().info("[Services: Payment]: Update subscription", params);
		return this.api()
			.post("api/payments/subscriptions/update/" + params.id, params)
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Update subscription]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getCustomerPortal() {
		this.log().info("[Services: Payment]: Get Customer Portal");
		return this.api()
			.get("api/payments/billing")
			.catch((error) => {
				this.log().error(
					"[Services: Payment: Get Customer Portal]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Payment",
	path: "payments"
});

export default api;
