<template>
  <VDialog
    v-if="isDialogOpen"
    scrollable
    :content-class="`custom-form-dialog ${customClass}`"
    :max-width="$vuetify.breakpoint.smAndDown ? '100%' : maxWidth"
    :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
    :persistent="true"
    :value="true"
    :width="width"
  >
    <slot name="above-card" />
    <VCard
      :color="transparent ? 'transparent' : backgroundColor"
      :class="{
        'form-dialog': true,
        'no-shadow': transparent,
      }"
    >
      <VCardTitle
        v-if="!hideTitle"
        style="flex: 0 1 auto"
        :class="{
          [cardTitleClass]: true,
          'pb-0': true,
          'border-none': hideTitleBorder,
        }"
      >
        <VRow
          dense
          color="transparent"
          class="align-center"
        >
          <VBtn
            v-if="!readonly && currentModel && currentModel.id && canDelete"
            text
            color="error"
            :icon="$vuetify.breakpoint.smAndDown"
            class="px-1"
            @click.native="$emit('deleteItem', [currentModel, () => $emit('toggle:form:dialog')])"
          >
            <VIcon>delete</VIcon>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              Delete
            </template>
          </VBtn>
          <slot
            name="before-title"
            :props="{
              isFormLoading,
              isFormReady,
            }"
          />
          <slot name="title">
            <span class="text-truncate text-no-wrap">{{ title }}</span>
          </slot>
          <VSpacer />
          <slot name="actions">
            <VBtn
              v-if="dismissable"
              icon
              @click.native="$emit('toggle:form:dialog')"
            >
              <VIcon color="error">
                close
              </VIcon>
            </VBtn>
          </slot>
        </VRow>
      </VCardTitle>
      <VCardText
        v-if="isDialogOpen"
        :class="{
          'pt-0': true,
          'mt-4': hideTitle,
          'px-0': $vuetify.breakpoint.smAndDown
        }"
        :style="{flex: '1 1 100%', overflowY: !scrollable ? 'hidden': 'auto'}"
      >
        <VForm 
          style="height: 100%"
          @submit.prevent
        >
          <slot
            :isFormLoading="isFormLoading"
            :isFormReady="isFormReady"
            :saveForm="saveForm"
          />
        </VForm>
      </VCardText>
      <VCardActions 
        v-if="!hideActions"
        style="flex: 0 1 auto"
        :class="cardActionsClass"
      >
        <slot 
          name="card-actions" 
          :props="{
            isFormLoading,
            isFormReady,
          }"
          :saveForm="saveForm"
        >
          <template v-if="newActions">
            <VBtn
              v-if="!readonly && currentModel && currentModel.id && !hideDeleteButton"
              color="error"
              class="ml-1"
              :disabled="isFormLoading || currentModel.isLoading"
              :loading="isFormLoading || currentModel.isLoading"
              @click.native="$emit('deleteItem', [currentModel, () => $emit('toggle:form:dialog')])"
            >
              <span class="white--text">Delete</span>
            </VBtn>
            <VSpacer />
            <VBtn
              color="#ececf1"
              style="box-shadow: none;"
              :disabled="isFormLoading || (currentModel || {}).isLoading"
              @click="$emit('toggle:form:dialog')"
            >
              <span style="color: #808080;">Cancel</span>
            </VBtn>
            <VBtn
              color="#60be39"
              class="ml-4 mr-2"
              style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25);"
              :disabled="!isFormReady || isFormLoading || (currentModel || {}).isLoading"
              :loading="isFormLoading || (currentModel || {}).isLoading"
              @click="saveForm"
            >
              <span class="white--text">{{ buttonText }}</span>
            </VBtn>
          </template>
          <template v-else>
            <VBtn
              large
              :disabled="isFormLoading"
              @click.native="$emit('toggle:form:dialog')"
            >
              <span 
                :style="{
                  'color': actionsTextColor,
                }"
              >
                {{ cancelText }}
              </span>
            </VBtn>
            <VSpacer />
            <slot name="footer-actions" />
            <VBtn
              v-if="!readonly"
              large
              color="primary wethrive"
              :disabled="isDisabled"
              :loading="isFormLoading"
              @click="saveForm"
            >
              <span 
                :style="{
                  'color': actionsTextColor,
                }"
              >
                {{ buttonText }}
              </span>
            </VBtn>
          </template>
        </slot>
      </VCardActions>
    </VCard>
    <slot name="extra-form" />
  </VDialog>
</template>

<script>
export default {
  name: "FormDialog",
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "1280px",
    },
    width: {
      type: String,
      default: "100%",
    },
    modelType: {
      type: String,
      default: "Model",
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    doSaveAction: {
      type: Function,
      default: null,
    },
    currentModel: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
    newActions: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideTitleBorder: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: [String],
      default: null,
    },
    cardTitleClass: {
      type: String,
      default: null,
    },
    cardActionsClass: {
      type: String,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    enableToggleSave: {
      type: Boolean,
      default: false,
    },
    actionsTextColor: {
      type: String,
      default: "inherit"
    },
  },
  data() {
    return {
      isFormReady: false,
      isFormLoading: true,
      isSaveDisabled: false,
    };
  },
  computed: {
    parentComponent() {
      if (this.$parent.$refs.hasOwnProperty("saveForm")) {
        return this.$parent;
      }
      return this.$parent.$parent;
    },
    isDisabled() {
      return !this.isFormReady || this.isFormLoading || this.disabled || this.isSaveDisabled;
    },
  },
  created() {
    this.$bus.$on("isFormReady", this.onFormReady);
    this.$bus.$on("startFormLoading", this.onFormLoading);
    this.$bus.$on("doneFormLoading", this.onFormLoadingDone);
    this.$bus.$on("toggleSave", this.onToggleSave);
    this.toggleIntercom();
  },
  destroyed() {
    this.toggleIntercom(true);
  },
  methods: {
    onFormReady(value) {
      this.isFormReady = value;
    },
    onFormLoading(value) {
      this.isFormLoading = true;
    },
    onFormLoadingDone(value) {
      this.isFormLoading = false;
    },
    onToggleSave(value) {
      if(this.enableToggleSave) {
        this.isSaveDisabled = value;
      }
    },
    toggleIntercom(show) {
      const container = document.querySelector("#intercom-container");
      if (container instanceof HTMLElement) {
        container.style.zIndex = show === true ? "2147483647" : "-1";
      }
    },
    getIsFormLoading() {
      return (this.form.activity || { isFormLoading: false }).isFormLoading;
    },
    getForm() {
      return (this.parentComponent.$refs["saveForm"] || {}).data || {};
    },
    saveForm() {
      if(this.doSaveAction && this.doSaveAction instanceof Function) {
        this.doSaveAction();
      } else {
        this.$bus.$emit("saveForm" + this.modelType.toLowerCase(), {
          id: this.currentModel ? this.currentModel.id : null,
          modelType: this.modelType,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  z-index: 2147483648 !important;
}
</style>

<style lang="scss">
.custom-form-dialog {
  overflow: visible;
  .v-card .v-card__title {
    height: 72px;
    flex: 0 0 72px;
    border-radius: 6px 6px 0 0;
    // background-color: #fafafa;
    .v-toolbar__content {
      height: 18px;
      color: #333636;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-transform: none;
      padding-left: 0px;
    }
  }
  .border-none {
    border: none !important;
  }
}

@media screen and (max-width: 959px) {
  .form-dialog > .v-card__text > .container,
  .form-dialog > .v-card__text > .container .v-card__text {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>