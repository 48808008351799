<template>
  <section class="reset-form">
    <div id="reset-form-header">
      <h3 class="text-upper" />
    </div>
    <VCard class="content-bg">
      <VCardTitle id="form-header">
        <h3>Reset Password</h3>
      </VCardTitle>
      <VCardText>
        <Alerts
          message-color="success"
          :messages="messages"
          :errors="errors"
        />
        <form
          v-if="!resetSent"
          class="form"
          name="reset"
          @submit.prevent="reset"
        >
          <VAlert
            type="info"
            :value="true"
          >
            Reset code confirmed. Please reset your password below.
          </VAlert>
          <TextField
            disabled
            required
            label="Email"
            name="email"
            type="email"
            :value="form.email"
          />
          <PasswordInput
            v-model="form.password"
            :editable="false"
            :error="$v.form.password.$error"
            @input="$v.form.password.$touch()"
            @blur="$v.$touch()"
          />
          <PasswordInput
            v-model="form.password_confirmation"
            label="Verify Password"
            :editable="false"
            :error="$v.form.password_confirmation.$error"
            @input="$v.form.password_confirmation.$touch()"
            @blur="$v.$touch()"
          />
          <VBtn
            class="wethrive"
            :disabled="!isFormReady"
            @click.prevent="reset"
          >
            {{ activity.isFormLoading ? "Updating..." : "Reset" }}
          </VBtn>
        </form>
        <VAlert
          v-else
          color="success"
          :value="true"
        >
          <template v-if="isOnMobile">
            Your password has been changed. You can now login with your new password.
          </template>
          <template v-else>
            Successfully reset your password...logging you in
          </template>
        </VAlert>
      </VCardText>
    </VCard>
    <VRow
      align-center
      id="reset-form-footer"
      class="mx-3"
    >
      <VCol id="reset">
        <VRow
          align-center
          justify-start
        >
          <router-link to="/login">
            Have an account? Login Here!
          </router-link>
        </VRow>
      </VCol>
      <VSpacer />
      <VCol id="badge">
        <VRow
          align-center
          justify-end
        >
          <span>Powered by&nbsp;</span>
          <span>
            <img
              style="height: 26px;"
              :src="WeThriveLogo"
            >
          </span>
        </VRow>
      </VCol>
    </VRow>
  </section>
</template>

<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import { AUTH_RESET, AUTH_REQUEST, AUTH_CSRF } from "@/store/actionTypes";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "ResetForm",
  mixins: [FormMixin],
  props: {},
  components: { PasswordInput },
  data() {
    return {
      modelType: "password",
      WeThriveLogo,
      isLoading: false,
      isEmailLoading: true,
      error: false,
      resetSent: false,
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
      token: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    token() {
      return this.$route.params.token || this.form.token;
    },
    isOnMobile() {
      return /webOS|iPhone|iPad|iPod|BlackBerry|Android/i.test(navigator.userAgent);
    },
  },
  created() {
    // this.form.email = window.localStorage.getItem("reset-email");
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.params.code;
  },
  methods: {
    ...mapActions({
      doReset: "auth/reset",
      doLogin: "auth/login",
    }),
    async reset() {
      if(!this.isFormReady) {
        return false;
      }
      await this.saveFormModel(() => {
        this.form.email = this.form.email.toLowerCase();
        return this.doReset(this.form).then((result) => {
          this.$log.debug("ResetForm.reset: Sent");
          this.resetSent = true;
          window.localStorage.removeItem("reset-email");
          this.$bus.$emit(
            "notificationMessage",
            "Your password was successfully reset"
          );
          if(this.isOnMobile) {
            return result;
          }
          this.doLogin({
            username: this.form.email,
            password: this.form.password,
          }).then(() => {
            this.$log.debug("ResetForm.login: Logged in");
            const ready = function() {
              // window.location.href = `${team ? '/'+team : ''}/app/dashboard`;
              this.$log.debug("ResetForm.login: User is available", this.$team);
              const redirect = this.$auth.redirect();
              this.$router.push(
                redirect instanceof Object
                  ? {
                      name: redirect.from.name,
                      params: redirect.from.params,
                      query: redirect.from.query,
                    }
                  : {
                      name: "dashboard",
                      params: {
                        team: this.$team.slug,
                      },
                    }
              );
            };

            setTimeout(() => {
              if (!this.$auth.ready()) {
                this.$auth.ready(ready);
              } else {
                ready.call(this);
              }
            }, 500);
            // Need to set the current team for the user using API
            // this.$watch(
            //   "$hasUser",
            //   function(hasUser, oldValue) {
            //     if (hasUser && this.$auth.check()) {
            //       // window.location.href = `${team ? '/'+team : ''}/app/dashboard`;
            //       this.$log.debug("ResetForm.login: User is available");
            //       this.$router.push({
            //         name: "dashboard",
            //         params: {
            //           team: this.$team.slug
            //         }
            //       });
            //     }
            //   },
            //   { immediate: true }
            // );
          });
          return result;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.reset-form {
  margin: auto;

  #reset-form-header h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  #circle {
    display: inline-block;
    background-color: #9bf2dc;
    border-radius: 50%;
    line-height: 5rem;
    height: 5rem;
    width: 5rem;
    text-align: center;
  }

  .form {
    background-color: $white-color;
    padding: 1rem;

    #form-header {
      border-bottom: 1px solid $border-color;
      margin: 0 -1rem 1rem -1rem;

      h3 {
        color: $content-title-color;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    label {
      font-size: 0.8rem;
    }

    span input {
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $border-color;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    button {
      border: 1px;
      box-shadow: none;
      width: 70%;
      margin-left: 15%;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      color: white;
    }

    #amnesia {
      text-align: right;
      font-size: 0.8rem;

      a {
        color: $font-light-color;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  #reset-form-footer {
    margin-top: 20px;
    font-size: 0.8rem;

    #amnesia a,
    #reset a {
      color: $font-light-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }

    #badge {
      grid-area: badge;
      text-align: right;

      span {
        display: inline-block;
      }
    }
  }
}
</style>
