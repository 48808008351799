<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card"
    class="py-2"
    :loading="isFirstLoad && !isDashboard"
  >
    <VRow>
      <VCol cols="12">
        <VAlert
          v-if="!hasLessons"
          type="info"
          style="font-size: 16px;"
          :value="true"
        >
          No {{ featureName(modelType).pluralize() }} assigned!
        </VAlert>
        <VCard v-else-if="($isUserAdmin || $isUserMentor) && !$viewProgramAsStudent">
          <VCardText class="pl-4">
            <DataViewUI
              hide-actions
              hide-main-title
              hide-records-info
              default-view-mode="grid"
              background-color="transparent"
              :data="prepareLessons"
              :md="colMd"
              :lg="colLg"
              :enable-infinite-loader="enableInfiniteLoader"
              @infiniteLoad="onInfiniteLoad"
            >
              <template
                slot="card"
                slot-scope="props"
              >
                <LessonHomeCard
                  is-back-to-default
                  :count="(model.lessons_count - localLessons.data.length) + 1"
                  :show-count="checkShowCount(props.index)"
                  :active="!props.item.is_locked"
                  :item="props.item"
                  :colors="colors"
                  :parent-model="model"
                  :loading="props.item.loading || props.item.isDuplicating"
                  :processing="props.item.processingDuplicate"
                  :visible-to="getVisibleToForProgram(props.item, model.id)"
                  @toggleItem="onToggle"
                  @viewItem="onSetViewDialogModel"
                  @deleteItem="doDeleteItem"
                  @viewSubmitted="onToggleSubmittedDialog"
                  @duplicateItem="onDuplicateItem"
                  @showMore="$emit('toggleTab', modelType.pluralize())"
                >
                  <template
                    v-if="$isUserAdmin && $viewProgramAsAdmin"
                    #status-options
                  >
                    <VDivider />
                    <VList class="pt-1">
                      <VListItem>
                        <VIcon
                          size="20px"
                          class="pr-2"
                        >
                          mdi-ballot-outline
                        </VIcon>
                        <VListItemTitle>
                          Status
                        </VListItemTitle>
                      </VListItem>
                    </VList>
                    <VDivider />
                    <VRadioGroup
                      hide-details
                      class="px-4 my-2"
                      :value="getLessonStatus(props.item)"
                    >
                      <VRadio
                        v-for="(option, index) in statusOptions"
                        color="success"
                        class="view-options pb-1"
                        :key="index"
                        :label="option.title"
                        :value="option.value"
                        @change="onStatusChange(props.item, option.value)"
                      />
                    </VRadioGroup>
                  </template>
                  <template
                    slot="menu-items"
                    slot-scope="{ previewOnly, isBackToDefault }"
                  >
                    <CardMenuItems
                      from-program
                      :item="props.item"
                      :preview-only="previewOnly"
                      :is-back-to-default="isBackToDefault"
                      @viewSubmitted="onToggleSubmittedDialog"
                      @duplicateItem="onDuplicateItem"
                      @deleteItem="doDeleteItem"
                    >
                      <template #extra-menu>
                        <VListItem 
                          v-if="$isUserAdmin && $viewProgramAsAdmin && !previewOnly"
                          @click="removeItem(props.index, props.item)"
                        >
                          <VIcon
                            size="20px"
                            class="pr-2"
                          >
                            mdi-book-remove
                          </VIcon>
                          <VListItemTitle>Remove from {{ featureName("Program").singularize() }}</VListItemTitle>
                        </VListItem>
                      </template>
                    </CardMenuItems>
                  </template>
                </LessonHomeCard>
              </template>
            </DataViewUI>
          </VCardText>
        </VCard>
        <PreviewLessons
          v-else
          is-back-to-default
          hide-preview-info
          :lessons="prepareLessons.data || {}"
          :is-dashboard="isDashboard"
          :custom-card-class="{
            'px-5': true,
          }"
          @toggleTab="$emit('toggleTab', modelType.pluralize())"
        />
      </VCol>
      <FormDialog
        :fullscreen="true"
        :hide-loverlay="true"
        :model-type="modelType"
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel ? currentModel.title : ''"
        @deleteItem="doDeleteItem"
        @toggleFormDialog="onToggleFormDialog"
      >
        <Save
          ref="saveForm"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
        />
      </FormDialog>
      <Dialog
        max-width="75%"
        title="Submitted"
        :is-dialog-open="submittedDialogs.submitted"
        @toggle:dialog="onToggleSubmittedDialog"
      >
        <Submitted
          mode="dialog"
          :assessment="submittedModel"
          :custom-filter="{
            programs: [model.id]
          }"
        />
      </Dialog>
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Confirm ref="confirmDelete" />
      <Confirm ref="confirmRemove" />
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import VisibleToMixin from "@/mixins/VisibleTo";
import DeleteOptionsMixin from "@/mixins/DeleteOptions";
import LessonHomeCard from "@/components/Lessons/Index/Card";
import CardMenuItems from "@/components/Lessons/Index/MenuItems";
import PreviewLessons from "@/components/Lessons/Index/PreviewLessons";
import Save from "@/components/Lessons/Save";
import Submitted from "@/components/Lessons/Submitted/index";

export default {
  name: "Lessons",
  mixins: [
    HasSubmittedDataMixin,
    ViewProgramSection,
    VisibleToMixin,
    HasAssessmentsMixin,
    DeleteOptionsMixin,
  ],
  components: {
    LessonHomeCard,
    Save,
    Submitted,
    PreviewLessons,
    CardMenuItems,
  },
  data() {
    return {
      modelType: "lesson",
      localLessons: {
        data: [],
      },
    }
  },
  watch: {
    activeTab: {
      handler: function(value) {
        // Only admin can see Applications tab, so next tab's index will alter
        if((this.$isUserAdmin && value == 3) || ((!this.$isUserAdmin || !this.$viewProgramAsAdmin) && value == 2)) {
          this.doSearch(this.onGetAll);
        } else {
          this.localLessons.data = this.model.lessons;
        }
        this.enableInfiniteLoader = value > 0;
      },
      immediate: true,
    },
    model: {
      handler: function(value) {
        this.localLessons.data = value.lessons;
      },
      deep: true,
    },
    infiniteLoad: {
      handler: function(value) {
        if(!this.isDashboard) {
          this.localLessons = value;
        }
      },
      deep: true,
    },
  },
  computed: {
    hasLessons() {
      if(this.prepareLessons.data.hasOwnProperty("all")) {
        return (this.prepareLessons.data.overdue.data.length > 0 
          || this.prepareLessons.data.started_not_complete.data.length > 0 
          || this.prepareLessons.data.all.data.length > 0);
      } else {
        return this.prepareLessons.data.length > 0;
      }
    },
    validLessonsForUsers() {
      if(this.$isUserMentor || this.$viewProgramAsMentor) {
        return {
          ...this.localLessons,
          data: this.localLessons.data.filter((lesson) => !lesson.is_locked && lesson.is_active && this.hasItemValidVisibility(lesson, this.model.id)),
        }
      }
      return this.localLessons;
    },
    prepareLessons() {
      if(this.$viewProgramAsStudent) {
        const incompletedLessons = this.validLessonsForUsers.data.filter((lesson) => lesson.result_for_user && !lesson.result_for_user?.completed_on);
        const allLessons = this.validLessonsForUsers.data.filter((lesson) => !lesson.result_for_user || lesson.result_for_user?.completed_on);
        return {
          all: {
            data: allLessons.slice(0, this.limitForDashboard),
            total: allLessons.length,
          },
          overdue: {
            data: [],
            total: 0,
          },
          started_not_complete: {
            data: incompletedLessons.slice(0, this.limitForDashboard),
            total: incompletedLessons.length,
          },
        };
      }
      return this.validLessonsForUsers;
    },
  },
  created() {
    this.deleteItemType = "Lesson";
  },
  methods: {
    ...mapActions({
      doGetAll: "lesson/getAll",
      doDelete: "lesson/delete",
      doToggle: "lesson/toggle",
      doDuplicateLesson: "lesson/duplicate",
    }),
    onDelete(lesson) {
      return this.doDelete(lesson.id).then((result) => {
        this.localLessons.data = this.localLessons.data.filter((item) => item.id !== lesson.id);
        return result;
      });
    },
    doDeleteItem(item) {
      return this.onDeleteItem(item).then((result) => {
        if(result) {
          this.$bus.$emit("deleteSectionItem", this.modelType.toLowerCase(), item);
          this.$bus.$emit("notificationMessage", `Deleted ${this.modelType} successfully!`);
        }
        return result;
      });
    },
    onDuplicate(params, item) {
      params.program_id = [this.model.id];
      params.identifier = this.model.id + "-" + params.identifier;
      return this.doDuplicateLesson(params).then((result) => {
        if(result.success) {
          const newTempItem = {
            ...item,
            isDuplicating: false,
            processingDuplicate: true,
            title: "Copy of " + item.title,
            identifier: params.identifier,
          };
          this.$delete(newTempItem, "id");
          this.localLessons.data.unshift(newTempItem);
          if(!this.isDashboard) {
            this.model[this.modelType.pluralize()].unshift(newTempItem);
          }
          this.setGroupItemLocalStorage("pending-duplications", "program-lesson", newTempItem.identifier, newTempItem);
        }
        return result;
      });
    },
    removeItem(index, item) {
      this.$set(this.localLessons.data[index], "loading", true);
      this.onRemoveItem(item).then((result) => {
        if(!result) {
          this.$set(this.localLessons.data[index], "loading", false);
        }
      });
    },
    onToggle(id) {
      const index = this.localLessons.data.findIndex(
        (d) => d.id === (id instanceof Object ? id.id : id)
      );
      if (index > -1) {
        this.$set(this.localLessons.data[index], "isLoading", true);
        this.doToggle(id).then((result) => {
          this.$set(this.localLessons.data[index], "isLoading", false);
          this.$set(
            this.localLessons.data[index],
            "is_locked",
            !this.localLessons.data[index].is_locked
          );
        });
      } else {
        this.$log.debug("[Lessons Index]: Missing lesson", id);
      }
    },
    onGetAll(params) {
      let updatedParams = {
        ...params,
        sort: "priority_relation",
        group: true,
        filter: {
          programs: [this.model.id],
          strict: true,
        },
      };
      return this.doGetAll(updatedParams).then((result) => {
        if(!this.$isUserStudent) {
          result.data = this.updateLocalPendingDuplications(this.modelType, result.data);
        }
        return result;
      });
    },
    checkShowCount(key) {
      if(this.isDashboard) {
        return this.model.lessons_count > this.limitForDashboard && key === this.validLessonsForUsers.data.length - 1;
      }
      return false;
    },
  }
}
</script>