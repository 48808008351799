<template>
  <PlanIndex />
</template>

<script>
import PlanIndex from "@/components/Plan";
export default {
  name: "PlanPage",
  components: { PlanIndex },
};
</script>