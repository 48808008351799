var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VApp',{class:{
    wethrive: true,
    'select-team': _vm.dialogs.selectTeam,
  }},[(_vm.$auth.ready() && _vm.$hasUser && _vm.isFullyLoaded)?[(_vm.hasMessagesOrErrors)?_c('Notifications',{attrs:{"alt-view":_vm.altView,"messages":_vm.messages,"errors":_vm.errors,"timeout":_vm.notificationTimeout}}):_vm._e(),_c('VDialog',{attrs:{"fullscreen":"","origin":"top left","transition":"fade-transition"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('VContainer',{staticClass:"pt-0",staticStyle:{"background-color":"rgba(33, 33, 33, 0.46)"},attrs:{"fluid":"","id":"side-menu"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('VRow',[_c('VCol',[_c('VAppBarNavIcon',{staticClass:"wethrive",attrs:{"icon":"","width":"40","height":"40"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('VIcon',{attrs:{"color":"white"}},[_vm._v(" menu ")])],1)],1)],1),_c('MainMenu')],1)],1),_c('FeedNotificationPanel',{attrs:{"id":"notification-panel","active":_vm.dialogs.notificationDrawer,"channels":[`users.${_vm.$user.id}`]},on:{"toggle":_vm.onToggleNotifications,"input":_vm.onToggleNotifications}}),_c('UserMenuPanel',{attrs:{"id":"user-menu-panel","items":_vm.menuProfileItems,"active":_vm.dialogs.profileMenu},on:{"toggle":_vm.onToggleProfileMenu,"toggleNotifications":_vm.onToggleNotifications}}),_c('VAppBar',{style:([_vm.$isUserApproved ? '' : _vm.navBarStyle]),attrs:{"app":"","fixed":"","row":"","wrap":"","align-center":"","flat":"","max-height":"56px","id":"main-content-menu"}},[_c('TeamMenuActivator',{attrs:{"bottom":"","button-classes":"ml-n3"},on:{"click":_vm.onToggleTeamsDialog}}),(_vm.$isUserApproved)?_c('VList',{staticClass:"d-flex ml-4",attrs:{"dense":"","color":"transparent","id":"top-left-menu"}},_vm._l((_vm.topLeftMenuItems),function(item,index){return _c('VListItem',{key:`top-left-menu-item-${index}`,class:{
            'active-top-left-menu': item.active,
          },attrs:{"to":item.route},on:{"click":function($event){return _vm.$router.push(item.route)}}},[_c('VListItemContent',[_c('VListItemTitle',{staticClass:"subtitle-1",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1):_vm._e(),_c('VSpacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('VRow',{attrs:{"align-center":""}},[_c('VCol',{attrs:{"elevation":"0","text":""}})],1):_vm._e(),_c('VSpacer'),_c('VRow',{style:({
          marginRight: _vm.$vuetify.breakpoint.mdAndUp ? '20px' : '0px',
        }),attrs:{"row":"","wrap":"","justify-end":"","align-center":"","id":"profile-menu"}},[_c('VCol',[_c('VRow',{attrs:{"row":"","justify-end":"","align-center":""}},[(_vm.isEnabledFeature('global_search'))?_c('GlobalSearch'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?[(
                  !_vm.$isUserApproved &&
                    !_vm.$team.is_enterprise &&
                    _vm.$team.enable_customization
                )?_c('VBtn',{class:{
                  'mr-2': true,
                  'white--text': true,
                },style:({
                  boxShadow: '0px 4px 4px 0 rgba(255, 115, 78, 0.25)',
                  backgroundImage:
                    'linear-gradient(to top, #ff4f45, #ff8752)',
                  fontSize: '1.5rem !important',
                  borderRadius: '10px',
                }),attrs:{"height":"40px"},on:{"click":function($event){return _vm.navigateTo({ name: 'lesson.index' })}}},[_vm._v(" Learn More ")]):_vm._e(),(
                  _vm.$isUserApproved ||
                    (!_vm.$isUserApproved &&
                      !_vm.$team.is_enterprise &&
                      _vm.$team.enable_customization)
                )?_c('VBtn',{class:{
                  'mr-2 px-0': true,
                },style:({
                  backgroundColor: _vm.$intercom.visible ? '#ddd' : '#fff',
                }),attrs:{"x-small":"","width":"40px","height":"40px"},on:{"click":_vm.onToggleIntercom}},[_c('VIcon',[_vm._v(" mdi-help ")])],1):_vm._e(),(_vm.$isUserApproved)?_c('ChatNotification',{staticClass:"mr-3",attrs:{"mode":"link","channels":[`chat.${_vm.$user.id}`]}}):_vm._e(),(_vm.isEnabledFeature('calendar'))?_c('VTooltip',{staticClass:"mx-1",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('VBtn',_vm._g({attrs:{"slot":"activator","text":"","icon":"","color":"primary","width":"40px","height":"40px"},on:{"click":function($event){return _vm.navigateTo({
                        name: 'calendar.index',
                        hash: _vm.$user.new_rsvps_count ? '#rsvps' : '',
                      })}},slot:"activator"},on),[_c('Badge',{attrs:{"top":"","right":"","overlap":"","offset-y":"50%","color":"primary","value":_vm.$user.new_rsvps_count > 0},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" ! ")]},proxy:true}],null,true)},[_c('VIcon',{staticStyle:{"font-size":"24px"},attrs:{"large":"","color":"primary","size":24}},[_vm._v(" calendar_today ")])],1)],1)]}}],null,false,2791494467)},[_c('span',[(_vm.$user.new_rsvps_count > 0)?[_vm._v("You have new RSVPs!")]:[_vm._v("No new RSVPs")]],2)]):_vm._e(),(
                  _vm.$isUserApproved ||
                    (!_vm.$isUserApproved &&
                      !_vm.$team.is_enterprise &&
                      _vm.$team.enable_customization)
                )?_c('FeedNotification',{staticClass:"mr-2",attrs:{"mode":"link","channels":[`users.${_vm.$user.id}`]},on:{"toggle":function($event){return _vm.onToggleNotifications(null)}}}):_vm._e()]:_vm._e(),_c('VBtn',{staticClass:"ml-1",attrs:{"text":"","icon":"","width":"40px","height":"40px","color":"white"},on:{"click":function($event){_vm.dialogs.profileMenu = !_vm.dialogs.profileMenu}}},[_c('VAvatar',{staticStyle:{"border-radius":"10px","cursor":"pointer"},attrs:{"size":"40"}},[_c('img',{attrs:{"src":_vm.userAvatar}})])],1)],2)],1)],1)],1),_c('VMain',{staticClass:"overflow-hidden",style:(_vm.globalStyle),attrs:{"id":"main-content-content"}},[_c('VRow',{staticClass:"my-0"},[(_vm.$showLeftMainMenu && _vm.$isUserApproved)?_c('VCol',{staticClass:"justify-left",attrs:{"cols":"auto"}},[_c('LeftSideMainMenu')],1):_vm._e(),_c('VCol',{class:{
            'justify-right main-content-container overflow-x-hidden overflow-y-auto py-0': true,
            'pl-0': _vm.$showLeftMainMenu && _vm.$isUserApproved,
          },staticStyle:{"height":"calc(100vh - 66px)"}},[_c('VContainer',{staticClass:"overflow-x-hidden",attrs:{"fill-height":"","fluid":"","py-0":"","pr-8":"","pl-8":!(_vm.$showLeftMainMenu && _vm.$isUserApproved),"pb-6":_vm.$vuetify.breakpoint.smAndDown,"px-0":(_vm.$team.enable_customization &&
                !_vm.$isUserApproved &&
                _vm.$route.name == 'dashboard') ||
                _vm.$vuetify.breakpoint.smAndDown,"px-2":_vm.$vuetify.breakpoint.smAndDown,"align-start":_vm.$hasUser,"align-center":!_vm.$hasUser}},[(_vm.$hasUser)?_vm._t("main-content"):_c('VProgressLinear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary"}})],2)],1)],1)],1),_c('SelectTeam',{attrs:{"select-team":_vm.dialogs.selectTeam},on:{"close":_vm.onToggleTeamsDialog}}),_c('LoginModal',{attrs:{"is-dialog-open":_vm.dialogs.login},on:{"toggle:dialog":_vm.onToggleLoginDialog,"loggedIn":_vm.onToggleLoginDialog}}),_c('VBottomSheet',{staticStyle:{"margin-bottom":"20px","border-radius":"0px"},attrs:{"persistent":"","hide-overlay":"","width":"100%"},model:{value:(_vm.dialogs.uploadProgress.dialog),callback:function ($$v) {_vm.$set(_vm.dialogs.uploadProgress, "dialog", $$v)},expression:"dialogs.uploadProgress.dialog"}},[_c('VCard',{staticStyle:{"border-radius":"0px"}},[_c('VCardText',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.dialogs.uploadProgress.value < 100)?_c('span',{staticClass:"px-1 py-0"},[_vm._v("Uploading. Please wait..."),_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.dialogs.uploadProgress.value))+"%")])]):_c('span',{staticClass:"px-1 py-0"},[_vm._v("Processing. Please wait...")]),_c('VBtn',{staticClass:"error",attrs:{"text":"","small":""},on:{"click":_vm.onToggleUploadDialog}},[_vm._v(" Cancel ")])],1),_c('VProgressLinear',{staticClass:"px-2 py-1 my-2",attrs:{"stream":"","rounded":"","height":"10","buffer-value":_vm.dialogs.uploadProgress.value}})],1)],1)],1)]:_c('VRow',{attrs:{"row":"","fluid":"","justify-center":"","align-center":"","fill-height":""}},[_c('VProgressCircular',{attrs:{"indeterminate":"","rounded":"","stream":"","color":"primary"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }