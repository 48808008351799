<script>
export default {
  computed: {
    menuItems() {
      const allMenusItems = [
        ...this.allFeatures.items,
        ...this.adminSettingsMenuItems.items,
        ...this.programMenuItems.items,
      ];
      let menuItem = this.findCurrentMenuItem(allMenusItems);
      if(menuItem) {
        if(menuItem.category == "adminSettings") {
          return this.adminSettingsMenuItems;
        } else if(menuItem.category == "features") {
          return this.allFeatures;
        } else {
          return this.programMenuItems;
        }
      }
      return this.allFeatures;
    },
    activeMenuItem() {
      const allMenusItems = [
        ...this.allFeatures.items,
        ...this.programMenuItems.items,
        ...this.adminSettingsMenuItems.items,
        {
          title: this.featureName("Programs"),
          icon: require("../images/programs-black-icon.png"),
          routeName: "program.index",
          routeGroup: "program",
          enabled: false,
          isImage: true,
          category: "programSections",
        },
      ];
      return this.findCurrentMenuItem(allMenusItems);
    },
    allFeatures() {
      const env = this.$root.env;
      return {
        header: "Content Library",
        items: [
          {
            priority: 3,
            title: this.featureName("Deliverables"),
            icon: require("../images/forms-black-icon.png"),
            routeName: "deliverable.index",
            routeGroup: "deliverable",
            enabled: true,
            category: "features",
            isImage: true,
            subGroup: this.$isUserAdmin || this.$isUserMentor,
            subGroupItems: [
              {
                title: "View All",
                enabled: true,
                route: {
                  name: "deliverable.index",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                title: "Import Submitted",
                enabled: this.$isUserAdmin || this.checkMentorPermission("deliverable"),
                route: {
                  name: "deliverable.index",
                  hash: "#import",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                title: "Export Submitted",
                beta: this.isBetaFeature("deliverable_submitted_export"),
                enabled: this.$isUserAdmin || this.$isUserMentor,
                route: {
                  name: "deliverable.index",
                  hash: "#export",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
            ].filter((subItem) => subItem.enabled === true),
          },
          {
            priority: 1,
            title: this.featureName("Lessons"),
            icon: require("../images/lessons-black-icon.png"),
            routeName: "lesson.index",
            routeGroup: "lesson",
            enabled: this.isEnabledFeature("LESSONS"),
            beta: this.isBetaFeature("LESSONS"),
            category: "features",
            isImage: true,
            subGroup: true,
            subGroupItems: [
              {
                title: "View All",
                enabled: true,
                route: {
                  name: "lesson.index",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                title: "Preview",
                enabled: this.$isUserAdmin || this.$isUserMentor,
                route: {
                  name: "lesson.index",
                  hash: "#preview",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                title: "Export Results",
                beta: this.isBetaFeature("lesson_results_export"),
                enabled: this.$isUserAdmin || this.$isUserMentor,
                route: {
                  name: "lesson.index",
                  hash: "#export",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                title: `Saved ${this.featureName("Lessons")}`,
                enabled: this.$isUserStudent,
                route: {
                  name: "lesson.index",
                  hash: "#saved",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
            ].filter((subItem) => subItem.enabled === true),
          },
          {
            priority: 4,
            title: this.featureName("FAQs"),
            icon: require("../images/faq-black-icon.png"),
            routeName: "faq.index",
            routeGroup: "faq",
            enabled: true,
            isImage: true,
            category: "features",
          },
          {
            priority: 2,
            title: this.featureName("Content"),
            icon: require("../images/content-black-icon.png"),
            routeName: "content.index",
            routeGroup: "content",
            enabled: true,
            isImage: true,
            category: "features",
          },
        ].sortBy("priority").filter((item) => item.enabled === true)
      };
    },
    programMenuItems() {
      return {
        header: `${this.featureName("Program", "singularize")} Content`,
        items: [
          {
            name: "dashboard",
            title: "Dashboard",
            icon: require("../images/home-icon.png"),
            url: "dashboard",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#dashboard",
            },
          },
          {
            title: this.featureName("Programs"),
            icon: require("../images/programs-black-icon.png"),
            routeName: "program.index",
            routeGroup: "program",
            enabled: false,
            category: "programSections",
          },
          {
            name: "pipelines",
            title: this.featureName("Pipelines"),
            icon: require("../images/application-black-icon@3x.png"),
            activeIcon: require("../images/application-white-icon@3x.png"),
            url: "pipelines",
            category: "programSections",
            isImage: true,
            beta: false,
            enableBetaTooltip: false,
            enabled: this.$isUserAdmin && this.$viewProgramAsAdmin,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#pipelines",
            },
          },
          {
            name: "community",
            title: "Community",
            icon: require("../images/user-black-icon.png"),
            activeIcon: require("../images/user-white-icon.png"),
            url: "community",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#community",
            },
          },
          {
            name: "lessons",
            title: this.featureName("Lessons"),
            icon: require("../images/lessons-black-icon.png"),
            activeIcon: require("../images/lessons-white-icon.png"),
            url: "lessons",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#lessons",
            },
          },
          {
            name: "contents",
            title: this.featureName("Content"),
            icon: require("../images/content-black-icon.png"),
            activeIcon: require("../images/content-white-icon.png"),
            url: "contents",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#contents",
            },
          },
          {
            name: "deliverables",
            title: this.featureName("Deliverables"),
            icon: require("../images/forms-black-icon.png"),
            activeIcon: require("../images/forms-white-icon.png"),
            url: "deliverables",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#deliverables",
            },
          },
          {
            name: "faqs",
            title: this.featureName("FAQs"),
            icon: require("../images/faq-black-icon.png"),
            activeIcon: require("../images/faq-white-icon.png"),
            url: "faqs",
            category: "programSections",
            isImage: true,
            enabled: true,
            route: { 
              name: "program.view",
              params: {
                id: this.$route.params.id,
                team: this.$team.slug,
              },
              hash: "#faqs",
            },
          },
        ].filter((item) => item.enabled === true)
      };
    },
    adminSettingsMenuItems() {
      const env = this.$root.env;
      return {
        header: "Workspace Administration",
        items: [
          {
            title: this.featureName("Pipelines"),
            icon: require("../images/application-black-icon@3x.png"),
            routeGroup: "pipeline",
            subGroup: true,
            isImage: true,
            subGroupItems: [
              {
                title: "View All",
                route: {
                  name: "settings",
                  hash: "#pipelines",
                },
              },
              {
                title: "View Submitted",
                route: {
                  name: "settings",
                  hash: "#pipelines-submitted",
                },
              },
              {
                title: "Export Submitted",
                beta: this.isBetaFeature("pipeline_submitted_export"),
                route: {
                  name: "settings",
                  hash: "#pipelines-export",
                },
              },
            ],
            enabled: (this.$hasAccessToCurrentTeam && this.$isUserAdmin) || (this.$isUserMentor && this.$user.pipelines_count > 0),
            category: "adminSettings",
          },
          {
            title: "Plan & Billing Info",
            icon: "mdi-shopping",
            routeName: "plan",
            isImage: false,
            enabled: this.$isTeamOwner && !this.$team.is_free,
            category: "adminSettings",
          },
          {
            title: this.featureName("Admins & Users"),
            icon: require("../images/users-icon@3x.png"),
            routeName: "user.index",
            routeGroup: "user",
            enabled: this.$isUserAdmin,
            category: "adminSettings",
            isImage: true,
            subGroup: true,
            subGroupItems: [
              {
                enabled: false,
                title: "View All",
                route: {
                  name: "user.index",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                enabled: false,
                title: "Invitations",
                route: {
                  name: "user.index",
                  hash: "#send-invitations",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                enabled: false,
                title: "Import",
                route: {
                  name: "user.index",
                  hash: "#import",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                enabled: true,
                title: "Admin Licenses",
                route: {
                  name: "user.index",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                enabled: true,
                title: "Export",
                route: {
                  name: "user.index",
                  hash: "#export",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
              {
                enabled: false,
                title: `Create ${this.featureName("User", "singularize")}`,
                route: {
                  name: "user.index",
                  hash: "#create-user",
                  params: {
                    team: this.$team.slug,
                  },
                },
              },
            ].filter((item) => item.enabled === true),
          },
          {
            title: this.featureName("Reports"),
            icon: "mdi-chart-bar",
            routeName: "report.index",
            routeGroup: "report",
            enabled: this.$isUserAdmin && this.isEnabledFeature(env.VUE_APP_FEATURE_REPORTS),
            beta: this.isBetaFeature(env.VUE_APP_FEATURE_REPORTS),
            isImage: false,
            category: "adminSettings",
          },
          {
            title: "Workspace Settings",
            icon: require("../images/workspace-settings.png"),
            category: "adminSettings",
            routeGroup: "settings",
            enabled: this.$isUserAdmin,
            isImage: true,
            subGroup: true,
            subGroupItems: [
              {
                title: "General",
                url: "general",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#general",
                },
              },
              {
                title: "Profile",
                url: "profile",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#profile",
                },
              },
              {
                title: "Policies",
                url: "policies",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#policies",
                },
              },
              {
                title: "Support Details",
                url: "support",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#support",
                },
              },
              {
                title: this.featureName("Rename Things"),
                url: "rename-things",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#rename-things",
                },
              },
              {
                title: "Features",
                url: "features",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#features",
                },
              },
              {
                title: "Profile Header",
                url: "profile-header",
                enabled: false,
                route: {
                  name: "settings",
                  hash: "#profile-header",
                },
              },
              {
                title: "Applicant Portal",
                url: "applicant-portal",
                enabled: this.$team.is_enterprise,
                route: {
                  name: "settings",
                  hash: "#applicant-portal",
                },
              },
              {
                title: "Landing Page",
                url: "landing-page",
                enabled: true,
                route: {
                  name: "settings",
                  hash: "#landing-page",
                },
              },
              {
                title: "Customization",
                url: "customization",
                enabled:
                  this.$team.can_customize && this.isEnabledFeature("CUSTOMIZATION"),
                beta: this.isBetaFeature("CUSTOMIZATION"),
                route: {
                  name: "settings",
                  hash: "#customization",
                },
              },
            ].filter((item) => item.enabled === true),
          },
        ].filter((item) => item.enabled === true),
      };
    },
  },
  methods: {
    findCurrentMenuItem(allItems) {
      return allItems.find((item) => {
        if(item.subGroup) {
          const subMenuItem = item.subGroupItems.find((subItem) => {
            if(subItem.route.hash && (subItem.route.name + subItem.route.hash) === (this.$route.name + this.$route.hash)) {
              return subItem;
            } else if((subItem.route.name === this.$route.name) && !this.$route.hash) {
              return subItem;
            }
          });
          if(subMenuItem || this.$route.name.split(".")[0] === item.routeGroup) {
            return item;
          }
        } else {
          let itemUrl = item.routeName || (item.route.name + item.route.hash);
          if(itemUrl === (this.$route.name + (this.$route.name == "program.view" && !this.$route.hash ? "#dashboard" : this.$route.hash ))) {
            return item;
          }
        }
      });
    },
  },
};
</script>