<template>
  <AppLayout :enable-footer="false">
    <VContainer 
      fluid
      fill-height
    >
      <VRow
        justify-center
        class="overflow-x-hidden overflow-y-auto"
      >
        <VCol 
          lg="9"
          md="12"
          sm="12"
        >
          <EnterpriseConfirmation />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import EnterpriseConfirmation from "@/components/Account/Billing/EnterpriseConfirmation";
import WeThriveLogo from "@/images/logo_wethrivehub.png";

export default {
  name: "EnterpriseConfirmationPage",
  components: {
    EnterpriseConfirmation,
    AppLayout
  },
  data() {
    return {
      WeThriveLogo,
    }
  },
};
</script>